import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "owl.carousel";
import { useParams, Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Produit from "./components/produit_component";
import axios from "axios";
import { connect } from "react-redux";
import {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} from "./../actions/action";
import { useDispatch } from "react-redux";
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import './components/Produit_component.css'; // Import CSS file for styling
const Single_product = (props) => {
  const { productId } = useParams();
  const [taboption, setTabOption] = useState("description");
  const [imageoption, setImageOption] = useState("1");
  const [product, setProduct] = useState([]);
  const [elementsParfum, setElementsParfum] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [listRelated, setListRelated] = useState([]);
  const [relatedComposantPhares, setRelatedComposantPhares] = useState([]);
  const [listComposantPhares, setListComposantPhares] = useState([]);
  const [sousCategories, setSousCategories] = useState([]);
  const [sousCat, setSousCat] = useState([]);
  const [category, setCategory] = useState([]);

  const [activeThumb, setActiveThumb] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [elements, setElements] = useState([]);
  const [newPrice, setNewPrice] = useState();
  const [rating, setRating] = useState("");
  const [full, setFull] = useState("");
  const [mini, setMini] = useState("");
  const [ingred, setIngred] = useState("");
  const initialParfum = elementsParfum.length > 0 ? elementsParfum[0] : "";
  const [parfum, setParfum] = useState(initialParfum);
  const initialVolume = elements.length > 0 ? elements[0] : "";
  const [volume, setVolume] = useState(initialVolume);
  const [quantity, setQuantity] = useState(1);

  const dispatch = useDispatch();
  const notify = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    
  };

  const handleAddToCart = (event, product) => {
    event.preventDefault();
    dispatch(addToCart(product, quantity));
    notify("ajouté au panier!");
  };

  useEffect(() => {
    async function getCategFromAPI() {
      try {
      } catch (error) {
        console.error("Error retrieving the list:", error);
      }
    }

    getCategFromAPI();
  }, [product]);
  // Update the product state separately
  useEffect(() => {
    async function getProductFromAPI() {
      try {
        const response = await axios.get(`/produits/${productId}`);
        const responseData = response.data;
        try{
          let catids = responseData.categorie.split(",");
          const responseCateg = await axios.post("/categories/byids/", {
            ids: catids,
          });
  
          setCategory(responseCateg.data);
        }
        catch{

        }
      
        try{
          let souscatids = responseData.sous_categorie.split(",");
          const responsesouscateg = await axios.post("/sous_categorie/byids/", {
            ids: souscatids,
          });
         
          setSousCategories(responsesouscateg.data);
        }catch{

        }

       
      
        const ids1 = responseData.composant_phare.split(",");
        try {
          const response = await axios.post("/composants/phares", {
            ids: ids1,
          });

          setListComposantPhares(response.data);
          console.log(response.data);
        } catch (error) {
          console.error("Error retrieving the composant phare:", error);
        }

        const ids = responseData.produit_complementaire.split(",");

        try {
          const response = await axios.post("/produits/related", { ids });
          setListRelated(response.data);
        } catch (error) {
          console.error("Error retrieving the related product:", error);
        }
        if (responseData.volume !== "")
          setElements(responseData.volume.split("/"));
        if (responseData.parfum !== "")
          setElementsParfum(responseData.parfum.split("/"));
        setFull(draftToHtml(JSON.parse(responseData.description_full)));
        setMini(draftToHtml(JSON.parse(responseData.description_mini)));
        setIngred(draftToHtml(JSON.parse(responseData.composants)));

        setProduct(responseData);
        const deb=responseData.date_deb;
        const fin=responseData.date_fin;
        const currentDate = new Date();
        const debDate = new Date(deb);
        const finDate = new Date(fin);
        const periode=currentDate >= debDate && currentDate <= finDate;
       
        if (responseData.promo !== "0" && periode) {
         
          const promoValue = parseInt(responseData.promo); // Convert promo string to an integer
          const prixValue = parseFloat(responseData.prix); // Convert prix string to a float
         
          const discount = (prixValue * promoValue) / 100;
          setNewPrice(prixValue - discount);
        }
        else
        setNewPrice(responseData.prix);
      } catch (error) {
        console.error("Error retrieving the product:", error);
      }
    }

    getProductFromAPI();
  }, [productId]);

  const handletabChange = (event) => {
    event.preventDefault();
    setTabOption(event.target.id);
  };

  const handleThumbClick = (e, index) => {
    e.preventDefault();
    setActiveThumb(index);
    setActiveIndex(index);
  };

  const handleRadioChange = (event) => {
    setVolume(event.target.value);
  };

  const handleParfumChange = (event) => {
    setParfum(event.target.value);
  };
  const handleQuantityChange = (e) => {
    setQuantity(Number(e.target.value));
  };

  const [showZoomedImage, setShowZoomedImage] = useState(false);
  const [zoomedImageUrl, setZoomedImageUrl] = useState("");



  const handleCarouselItemClick = (imageUrl) => {
    setZoomedImageUrl(imageUrl);
  };

  const handleZoomButtonClick = (imageUrl) => {
    setZoomedImageUrl(imageUrl);
  };
  const handleCloseZoom = () => {
    setZoomedImageUrl(null);
  };
  return (
    <>
      <Helmet>
    
    <meta name="description" content="Veracos. Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté."/>

     </Helmet>
      <>
        <div className="breadcrumb-option">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb__links">
                  <a href="#/shop">
                    <i className="fa fa-home" /> Produits
                  </a>
                  <a href="#">Details Produit </a>
                  <span>{product.nom}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Breadcrumb End */}
        {/* Product Details Section Begin */}
        <section className="product-details spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="product__details__pic">
                  <div className="product__details__pic__left product__thumb nice-scroll">
                    {[
                      "https://veracoslab.com/API/public/uploads/" +
                        product.img1,
                      "https://veracoslab.com/API/public/uploads/" +
                        product.img2,
                      "https://veracoslab.com/API/public/uploads/" +
                        product.img3,
                      "https://veracoslab.com/API/public/uploads/" +
                        product.img4,
                    ].map((thumb, index) => (
                      <a
                        key={index}
                        className={`pt ${
                          activeThumb === index ? "active" : ""
                        }`}
                        href={`#product-${index + 1}`}
                        onClick={(e) => handleThumbClick(e, index)}
                      >
                        <img src={thumb} alt="" />
                      </a>
                    ))}
                  </div>
                  <div className="product__details__slider__content">
                  <Carousel
  indicators={false}
  activeIndex={activeIndex}
  onSelect={setActiveIndex}
  interval={null}
>
  {[
    {
      url:
        "https://veracoslab.com/API/public/uploads/" +
        product.img1,
      alt: product.img1_titre,
      title: product.img1_titre,
      description: product.img1_descrip,
    },
    // Add other carousel items here
  ].map((image, index) => (
    <Carousel.Item key={index}   >
      <img
        className="d-block w-100 product__big__img"
        src={image.url}
        alt={image.alt}
        title={image.title}
        description={image.description}
        data-hash={`product-${index + 1}`}
      />
       <button
                  className="zoom-button"
                  onClick={() => handleZoomButtonClick(image.url)}
                >
                  <span className="icon_search search-switch"></span>
                </button>
    </Carousel.Item>
  ))}
</Carousel>
    {/* Zoomed image */}
    {zoomedImageUrl && (
        <div className="zoomed-image-overlay">
           <button className="close-zoom" onClick={handleCloseZoom}>
            X
          </button>
          <img
            className="zoomed-image"
            src={zoomedImageUrl}
            alt="Zoomed Image"
          />
        </div>
      )}
      </div>
      
                </div>
              </div>
              <div className="col-lg-6">
                <div className="product__details__text">
                  <h3>
                    {product.nom}
                    <span>
                      {category.map((cat) => (
                        <>{cat.nom}-</>
                      ))}

                      {sousCategories.map((cat) => (
                        <>{cat.nom}-</>
                      ))}
                    </span>
                  </h3>
                  <div className="rating">
                    {Array.from({ length: parseInt(product.rating, 10) }).map(
                      (_, index) => (
                        <i
                          key={index}
                          className="fa fa-star"
                          style={{ marginRight: "3px" }}
                        />
                      )
                    )}
                  </div>
                  { (new Date() >= new Date(product.date_deb) && new Date() <= new Date(product.date_fin)) === false && (
                    <div className="product__details__price">
                      {product.prix} TND
                    </div>
                  )}
                  {(product.promo  !== "0" && (new Date() >= new Date(product.date_deb) && new Date() <= new Date(product.date_fin)) === true) && (
                    <div className="product__details__price">
                      {newPrice} TND <span>{product.prix} </span>
                    </div>
                  )}

                  <p dangerouslySetInnerHTML={{ __html: mini }} />
                  <div className="product__details__button">
                    <div className="quantity">
                      <span>Quantité:</span>
                      <div className="pro-qty">
                        <input
                          type="number"
                          defaultValue={1}
                          onChange={(e) => setQuantity(Number(e.target.value))}
                        />
                      </div>
                    </div>
                    <a href="#" className="cart-btn"
                     onClick={(event) => handleAddToCart(event,{ id: product.id, titre: product.nom, img: "https://veracoslab.com/API/public/uploads/"+product.img1,prix : newPrice,promo:product.promo,rating:product.rating,quantity:quantity })} id={product.id}
                    >
                      <span className="icon_bag_alt" /> Ajouter au panier
                    </a>
                  </div>
                  <div className="product__details__widget">
                    <ul>
                      <li>
                        <span>UGS:</span>

                        <p>{product.ugs}</p>
                      </li>
                      {product.parfum !== "" && (
                        <li>
                          <span>Parfum:</span>
                          <div className="size__btn">
                            {elementsParfum?.map((option, index) => (
                              <div key={index}>
                                <input
                                  type="radio"
                                  id={`parfum-btn-${index}`}
                                  value={option}
                                  checked={parfum === option}
                                  onChange={handleParfumChange}
                                  style={{ marginRight: "2px" }}
                                />
                                <label htmlFor={`parfum-btn-${index}`}>
                                  {option}
                                </label>
                              </div>
                            ))}
                          </div>
                        </li>
                      )}
                      {product.volume !== "" && (
                        <li>
                          <span>Volume:</span>
                          <div className="size__btn">
                            {elements?.map((element, index) => (
                              <div key={index}>
                                <input
                                  type="radio"
                                  id={`size-btn-${index}`}
                                  value={element}
                                  checked={volume === element}
                                  onChange={handleRadioChange}
                                  style={{ marginRight: "2px" }}
                                />
                                <label htmlFor={`size-btn-${index}`}>
                                  {element}
                                </label>
                              </div>
                            ))}
                          </div>
                        </li>
                      )}
                      {(product.promo !== "0" && (new Date() >= new Date(product.date_deb) && new Date() <= new Date(product.date_fin))) && (
                        <li>
                          <span>Promotions:</span>
                          <p>en promo</p>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              {product.video !== "" && (
                <div
                  className="col-lg-12 col-md-12 col-sm-12"
                  style={{ marginTop: "50px" }}
                >
                  <iframe
                    width="1200px"
                    height="600px"
                    src={product.video}
                    title="YouTube Video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              )}

              <div className="col-lg-12">
                <div className="product__details__tab">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className={
                          taboption === "description"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        href=""
                        id="description"
                        onClick={handletabChange}
                      >
                        Description
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          taboption === "Composants"
                            ? "nav-link active"
                            : "nav-link "
                        }
                        onClick={handletabChange}
                        href=""
                        id="Composants"
                      >
                        Ingrédients
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          taboption === "Composants Phare"
                            ? "nav-link active"
                            : "nav-link "
                        }
                        onClick={handletabChange}
                        href=""
                        id="Composants Phare"
                      >
                        Ingrédient(s) Phare(s)
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {taboption === "description" && (
                      <>
                        <div id="tabs-1" role="tabpanel">
                         

                          <p dangerouslySetInnerHTML={{ __html: full }} />
                        </div>
                      </>
                    )}

                    {taboption === "Composants" && (
                      <>
                        {" "}
                        <div id="specification" role="tabpanel">
                         

                          <p dangerouslySetInnerHTML={{ __html: ingred }} />
                        </div>
                      </>
                    )}

                    {taboption === "Composants Phare" && (
                      <>
                        {" "}
                        <div id="tabs-3" role="tabpanel">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="row">
                              {listComposantPhares &&
                                listComposantPhares.map((element) => (
                                  <div className="col-6">
                                    <div className="trend__item">
                                      <div className="trend__item__pic">
                                        <img
                                          src={`https://veracoslab.com/API/public/uploads/${element.img}`}
                                          alt={element.img1_titre}
                                          titre={element.img1_titre}
                                          description={element.img1_descrip}
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "30px",
                                          }}
                                        />
                                      </div>
                                      <div className="trend__item__text">
                                        <h6>
                                          <strong>{element.nom}</strong>
                                        </h6>
                                        <div className="rating">
                                          {element.description}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* related section*/}
            {product.produit_complementaire !== "" && (
              <div className="row">
                <div className="col-lg-12 text-center">
                  <div className="related__title">
                    <h5>Produits similaires</h5>
                  </div>
                </div>

                {listRelated &&
                  listRelated?.map((prod, index) => (
                    <div className="col-lg-3 col-md-4 col-sm-6 mix women">
                      {index < 4 && (
                        <Produit
                          prodId={prod.id}
                          img_url={`https://veracoslab.com/API/public/uploads/${prod.img1}`}
                          titre={prod.nom}
                          promo={prod.promo}
                          stock={prod.stock}
                          deb={prod.date_deb}
                          fin={prod.date_fin}
                          rating={prod.rating}
                          prix={`${prod.prix}`}
                        />
                      )}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </section>
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
});

const mapDispatchToProps = {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
};
export default connect(mapStateToProps, mapDispatchToProps)(Single_product);
