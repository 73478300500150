import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use local storage

const middleware = [thunk];


// Configure persist options
const persistConfig = {
  key: 'root', // Key for the root of the storage
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Merge the createStore and composeWithDevTools functions
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);
export default store;