import { combineReducers } from "redux";
import auth from "./auth";
import message from "./messages";
import cartReducer from "./cartReducer"; 
import filtreReducer from "./filtreReducer"; 
export default combineReducers({
  cart: cartReducer,
  auth,
  message,
  filtre:filtreReducer,
});