import React from 'react'
import { Helmet } from 'react-helmet-async';
const Cgv = () => {
  return (
    <>
      <Helmet>
      
        <meta name="description" content="Veracos. Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté."/>
 
         </Helmet>
      <div className="breadcrumb-option">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="breadcrumb__links">
            <a href="/">
              <i className="fa fa-home" /> Home
            </a>
            <span>Conditions générales de ventes</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Breadcrumb End */}
  {/* Contact Section Begin */}
  <section className="contact spad">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="contact__content">
            <div className="contact__address">
              <h5>Conditions générales de ventes</h5>
              <ul>
                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 1 : Objet
                  </h6>
                  <p>
                  Les présentes CGU ou Conditions Générales d’Utilisation encadrent juridiquement l’utilisation des services du site veracoslab.com.tn
Constituant le contrat entre la société Veracos et l’Utilisateur, l’accès au site doit être précédé de l’acceptation de ces CGU. L’accès à cette plateforme signifie l’acceptation des présentes CGU.

                  </p>
                </li>

                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 2 : Mentions légales
                  </h6>
                  <p>
                  L’édition du site Veracoslab.com.tn est assurée par la société Veracos SARL, dont le siège social est localisé au 04, rue Bir Mchergua, Borj Louzir, 2073 Ariana, Tunisie.
                  </p>
                </li>
              

                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 3 : Accès au site
                  </h6>
                  <p>
                  Le site veracoslab.com permet d’accéder gratuitement aux services suivants :<br/>
•	Vente d'accessoires et de produits cosmétiques<br/>
Le site est accessible gratuitement depuis n’importe où par tout utilisateur disposant d’un accès à Internet.<br/> Tous les frais nécessaires pour l’accès aux services (matériel informatique, connexion Internet…) sont à la charge de l’utilisateur.<br/>
L’accès aux services dédiés aux membres s’effectue à l’aide d’un identifiant et d’un mot de passe.<br/>
Pour des raisons de maintenance ou autres, l’accès au site peut être interrompu ou suspendu par l’éditeur sans préavis ni justification.

                    </p>
                </li>
              


              
                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 4 : Collecte des données
                  </h6>
                  <p>

                  Pour la création du compte de l’Utilisateur, la collecte des informations au moment de l’inscription sur le site est nécessaire et obligatoire. <br/>La collecte et le traitement d’informations personnelles s’effectuent dans le respect de la vie privée.<br/>
L’Utilisateur dispose du droit d’accéder, de rectifier, de supprimer et d’opposer ses données personnelles. L’exercice de ce droit s’effectue par :
<br/>•	Le formulaire de contact.
<br/>
•	Son espace client.

                    </p>
                </li>
              
             

                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 5 : Propriété intellectuelle
                  </h6>
                  <p>

                  Les marques, logos ainsi que les contenus du site veracoslab.com (illustrations graphiques, textes…) sont protégés par le Code de la propriété intellectuelle et par le droit d’auteur.
                  <br/>La reproduction et la copie des contenus par l’Utilisateur requièrent une autorisation préalable du site. Dans ce cas, toute utilisation à des usages commerciaux ou à des fins publicitaires est proscrite.
                  <br/>Veracoslab se réserve le droit de demander des dommages et intérêts en cas de d’atteinte à ses droits de propriété intellectuelle.


                    </p>
                </li>




                
                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 6 : Responsabilité
                  </h6>
                  <p>

                  Bien que les informations publiées sur le site soient réputées fiables, le site se réserve la faculté d’une non-garantie de la fiabilité des sources.<br/>
Les informations diffusées sur le site veracoslab sont présentées à titre purement informatif et sont sans valeur contractuelle.<br/> En dépit des mises à jour régulières, la responsabilité du site ne peut être engagée en cas de modification des dispositions administratives et juridiques apparaissant après la publication. <br/>Il en est de même pour l’utilisation et l’interprétation des informations communiquées sur la plateforme.<br/>
Le site décline toute responsabilité concernant les éventuels virus pouvant infecter le matériel informatique de l’Utilisateur après l’utilisation ou l’accès à ce site.<br/>
Le site ne peut être tenu pour responsable en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.<br/>
La garantie totale de la sécurité et la confidentialité des données n’est pas assurée par le site.<br/> Cependant, le site s’engage à mettre en œuvre toutes les méthodes requises pour le faire au mieux.<br/>


                    </p>
                </li>


      
                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 7 : Liens hypertextes
                  </h6>
                  <p>
                  Le site peut être constitué de liens hypertextes. <br/>En cliquant sur ces derniers, l’Utilisateur sortira de la plateforme. Cette dernière n’a pas de contrôle et ne peut pas être tenue responsable du contenu des pages web relatives à ces liens.<br/>
                    </p>
                </li>

                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 8 : Cookies
                  </h6>
                  <p>
                  Lors des visites sur le site, l’installation automatique d’un cookie sur le logiciel de navigation de l’Utilisateur peut survenir.<br/>
Les cookies correspondent à de petits fichiers déposés temporairement sur le disque dur de l’ordinateur de l’Utilisateur.<br/> Ces cookies sont nécessaires pour assurer l’accessibilité et la navigation sur le site.<br/> Ces fichiers ne comportent pas d’informations personnelles et ne peuvent pas être utilisés pour l’identification d’une personne.<br/>
L’information présente dans les cookies est utilisée pour améliorer les performances de navigation sur le site veracoslab.com.<br/>
En naviguant sur le site, l’Utilisateur accepte les cookies. Leur désactivation peut s’effectuer via les paramètres du logiciel de navigation.

                     </p>
                </li>
                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 9 : Publication par l’Utilisateur
                  </h6>
                  <p>
                  Le site Veracoslab permet aux membres de publier des commentaires.<br/>
Dans ses publications, le membre est tenu de respecter les règles de la Netiquette ainsi que les règles de droit en vigueur.<br/>
Le site dispose du droit d’exercer une modération à priori sur les publications et peut refuser leur mise en ligne sans avoir à fournir de justification.<br/>
Le membre garde l’intégralité de ses droits de propriété intellectuelle.<br/> Toutefois, toute publication sur le site implique la délégation du droit non exclusif et gratuit à la société éditrice de représenter, reproduire, modifier, adapter, distribuer et diffuser la publication n’importe où et sur n’importe quel support pour la durée de la propriété intellectuelle.<br/> Cela peut se faire directement ou par l’intermédiaire d’un tiers autorisé.<br/> Cela concerne notamment le droit d’utilisation de la publication sur le web et sur les réseaux de téléphonie mobile.<br/>
À chaque utilisation, l’éditeur s’engage à mentionner le nom du membre à proximité de la publication.<br/>
L’Utilisateur est tenu responsable de tout contenu qu’il met en ligne.<br/> L’Utilisateur s’engage à ne pas publier de contenus susceptibles de porter atteinte aux intérêts de tierces personnes.<br/> Toutes procédures engagées en justice par un tiers lésé à l’encontre du site devront être prises en charge par l’Utilisateur.<br/>
La suppression ou la modification par le site du contenu de l’Utilisateur peut s’effectuer à tout moment, pour n’importe quelle raison et sans préavis.<br/>
       </p>
                </li>

                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 10 : La commande
                  </h6>
                  <p>
                  Les commandes s’effectuent exclusivement sur Internet à l’adresse: veracoslab.com
Pour toute question relative au suivi de la commande, le Client peut contacter le Service Clients au 31 400 700 / 51 452 500 du lundi au vendredi de 9h30 à 17h00 ou envoyer un e-mail à contact@veracoslab.com
Toute confirmation de commande vaut acceptation des prix et descriptifs des produits mis en vente sur le présent site web.<br/>
Au moment de valider son panier, l’internaute valide les conditions générales de vente de Veracoslab.<br/> Cette action formalise le contrat de vente entre Veracoslab et le Client.<br/>
Nos offres de produits et les prix sont valables tant qu’ils sont visibles sur le site, dans la limite des stocks disponibles.<br/>
Dans le cas des produits en promotion, le Client prends note que les produits en promotion le sont dans la limite des stocks disponibles et que les promotions peuvent être limitées dans le temps.<br/>
Si toutefois une indisponibilité momentanée d’un produit après passation de votre commande est constatée, nous vous en informerons par e-mail dans les meilleurs délais.<br/>
Dans le cas d’une adresse erronée, d’un défaut de paiement ou de tout autre problème sur le compte de l’utilisateur, nous nous réservons le droit de stopper le traitement de la commande du Client jusqu’à résolution du problème, ou d’annuler la commande du Client et le rembourser suite à des ruptures de stock concernants les produits désirés.<br/>
Veracoslab se réserve le droit d’annuler ou de refuser toute commande d’un Client avec lequel il existerait un litige relatif au paiement d’une commande antérieure.<br/>
</p>
                </li>


                
                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 11 : Les tarifs et les modalités de paiement
                  </h6>
                  <p>
                  Les prix de vente des produits sur le site sont indiqués en TND et sont ceux en vigueur au moment de l’enregistrement de la commande par le Client.<br/> Veracoslab se réserve le droit de modifier ses prix à tout moment.<br/>
Les prix indiqués sur le site comprennent la taxe sur la valeur ajoutée (TVA) et La taxe professionnelle de compétitivité en Tunisie (FODEC) applicable au jour de la commande.<br/> Tout changement du taux applicable sera répercuté sur le prix des produits commercialisé sur le site.
Les prix ne comprennent pas les frais de traitement de la commande (expédition, emballage et manutention facturés en supplément du prix des produits achetés)
Produits en promotion : les produits en promotion le sont dans la limite des stocks disponibles et peuvent être arrêtés à tout moment.<br/>
Veracoslab accepte uniquement le paiement à la livraison.<br/>
Défaut de paiement : Veracoslab se réserve le droit de refuser d’effectuer une livraison ou d’honorer une commande émanant d’un Client qui n’aurait pas réglé en tout ou partie une commande précédente ou avec lequel un litige serait en cours d’administration.<br/>
Cas de force majeure : Veracoslab se réserve le droit d’annuler la commande d’un client s’il jugeait qu’il n’était pas dans les conditions d’honorer cette commande ou en cas d’erreur de prix.<br/>

                       </p>
                </li>


                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 12 : Préparation de commande, expédition et livraison
                  </h6>
                  <p>
                  Nos délais de préparation habituels sont de 1 à 4 jours ouvrables.<br/>
Les livraisons sont assurées par des sociétés de transports tierces tel que Aramex.<br/>
Les tarifs de préparation et de livraison de commande seront indiqués avant la validation de votre commande; ils sont susceptibles d’être modifiés à tout moment. Seront valables les tarifs appliqués au moment de la commande.<br/>

                    </p>
                </li>

                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 13 : Conformité des produits et réclamations
                  </h6>
                  <p>
                  Le transfert de propriété est réputé effectif au moment de l’expédition des produits par Veracoslab. <br/>Les articles commandés sont envoyés à l’adresse de livraison indiquée sur le bon de commande.<br/> Les informations indiquées par le Client lors de la passation de commande engagent celui-ci : en cas d’erreur dans le libellé des coordonnées du destinataire, Veracoslab ne saurait être tenu responsable de l’impossibilité dans laquelle il pourrait être de livrer.<br/>
Si, après la livraison, le Client s’aperçoit que les produits livrés ne correspondent pas à sa commande, présentent des défauts ou sont endommagés, il est tenu de faire une réclamation dans les 5 jours ouvrables suivant la livraison, affichée par le transporteur.<br/> Toute réclamation portée au-delà de ce délai ne sera pas traitée par nos services et notre responsabilité sera dégagée de fait.<br/>

                    </p>
                </li>



                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Article 14 : Droit applicable et juridiction compétente
                  </h6>
                  <p>
                  Le présent contrat est soumis à la législation tunisienne. <br/>L’absence de résolution à l’amiable des cas de litige entre les parties implique le recours aux tribunaux tunisiens compétents pour régler le contentieux.<br/>
                    </p>
                </li>

                <li>
                  <h6>
                    <i className="fa fa-arrow-right" /> Mentions légales
                  </h6>
                  <p>
                  Veracoslab.com est un site de vente en ligne d’accessoires et de produits cosmétiques.
                  
                  <br/>
                  <b>Sté Veracos SARL</b><br/>
                  <b>Adresse : </b>04, rue Bir Mchergua,
Borj Louzir, 2073 Ariana, Tunisie<br/>

<b>Tél : </b>+216 31 400 700<br/>
<b>E-Mail : </b>contact@veracoslab.com<br/>
                        </p>
                </li>

              </ul>
            </div>
        
          </div>
        </div>
    
      </div>
    </div>
  </section>

    </>
  )
}

export default Cgv
