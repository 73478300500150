import Produit from "./components/produit_component";
import Trend from "./components/trend_item";
import CountdownTimer from "./components/countdowntimer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet-async";
const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [cookies, setCookie] = useCookies();
  const [list, setList] = useState([]);
  const [produitpromo, setProduitpromo] = useState([]);
  const currentLocation = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [articles, setArticles] = useState([]);
  // Function to handle touch events for the carousel
  const handleTouch = (e) => {
    if (isMobile) {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const shouldScrollToTarget = currentLocation.pathname.includes("promos");

    if (shouldScrollToTarget) {
      const targetDiv = document.getElementById("divTarget");
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    }
  });

  useEffect(() => {
    async function getListFromAPI() {
      try {
        const response = await axios.get("/produits"); // Replace with the actual API endpoint

        // Assuming the response data is an array, update the state with the list

        setList(response.data);
      } catch (error) {
        console.error("Error retrieving the list:", error);
      }
    }

    async function getrandomproduit() {
      try {
        const response = await axios.get("/produitpromo"); // Replace with the actual API endpoint

        // Assuming the response data is an array, update the state with the list

        setProduitpromo(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error retrieving produit promo:", error);
      }
    }
    // Call the function to fetch the list from the API
    getListFromAPI();
    getrandomproduit();
  }, []);

  const [statics, setStatics] = useState([]);

  useEffect(() => {
    async function fetchStatics() {
      try {
        const response = await axios.get("/statics");
        setStatics(response.data);
      } catch (error) {
        console.error("Error fetching statics:", error);
      }
    }

    fetchStatics();
  }, []);

  useEffect(() => {
    async function fetchArticles() {
      try {
        const response = await axios.get("/articles");
        setArticles(response.data);
      } catch (error) {
        console.error("Error fetching statics:", error);
      }
    }

    fetchArticles();
  }, []);

  const diapoStatics = statics.filter((item) => item.type === "diapo");
  const headerStatics = statics.filter((item) => item.type === "header");
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Veracos. Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté."
        />
      </Helmet>
      <section className="categories" onTouchMove={handleTouch}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 p-0">
              <Carousel
                autoPlay
                interval={3000}
                showStatus={false}
                showArrows={true}
                showThumbs={false}
                infiniteLoop={true}
                swipeable={!isMobile}
              >
                {diapoStatics.map((item) => (
                  <div
                    key={item.id}
                    className="categories__item categories__large__item set-bg"
                    style={{
                      backgroundImage: `url("https://veracoslab.com/API/public/uploads/${item.image}")`,
                    }}
                  >
                    <div
                      className="categories__text"
                      style={{
                        backgroundColor: "rgba(255,255,255,0.7)",
                        borderRadius: "30px",
                      }}
                    >
                      <h1 style={{ fontSize: "26pt", margin: "10px" }}>
                        {item.title}
                      </h1>
                      <p>{item.description}</p>
                      {item.sous_categ && (
                        <Link
                          to={`/shop/sous_categorie/${item.sous_categ}/${item.sous_categ}`}
                          style={{ marginBottom: "20px" }}
                        >
                          Voir plus
                        </Link>
                      )} 
                      {item.categ && (
                        <Link
                          to={`/shop/categorie/${item.categ}/${item.categ}`}
                          style={{ marginBottom: "20px" }}
                        >
                          Voir plus
                        </Link>
                      )}
                      {item.categ && (
                        <Link
                          to={item.lien}
                          style={{ marginBottom: "20px" }}
                        >
                          Voir plus
                        </Link>
                      )}

                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="col-lg-4">
              <div className="row">
                {headerStatics.slice(0, 2).map((item, index) => (
                  <div key={index} className="col-lg-12 col-md-12 col-sm-6 p-0">
                    <div
                      className="categories__item set-bg"
                      style={{
                        backgroundImage: `url("https://veracoslab.com/API/public/uploads/${item.image}")`,
                      }}
                    >
                      <div className="categories__text">
                        <h4>{item.title}</h4>
                        {/* Assuming categ is used for the route path */}
                        {item.sous_categ ? (
                          <Link
                            to={`/shop/sous_categorie/${item.sous_categ}/${item.sous_categ}`}
                            style={{ marginBottom: "20px" }}
                          >
                            Voir plus
                          </Link>
                        ) : (
                          <Link
                            to={`/shop/categorie/${item.categ}/${item.categ}`}
                            style={{ marginBottom: "20px" }}
                          >
                            Voir plus
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Product Section Begin */}
      <section className="product spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="section-title">
                <h4>Nouveauté</h4>
              </div>
            </div>
          </div>
          <div className="row property__gallery">
            {list.slice(0, 8).map((prod, index) => (
              <div className="col-lg-3 col-md-4 col-sm-6 mix women">
                <Produit
                  prodId={prod.id}
                  img_url={`https://veracoslab.com/API/public/uploads/${prod.img1}`}
                  titre_img={prod.img1_titre}
                  description_img={prod.img1_descrip}
                  titre={prod.nom}
                  deb={prod.date_deb}
                  fin={prod.date_fin}
                  promo={prod.promo}
                  stock={prod.stock}
                  rating={prod.rating}
                  prix={`${prod.prix}`}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        className="banner set-bg"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "assets/img/banner/banner-1.jpg"
          })`,
          height: "500px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8 m-auto">
              <div className="banner__slider ">
                <div className="banner__item">
                  <div className="banner__text">
                    <span>Articles les plus lus</span>

                    <Carousel
                      infiniteLoop={true}
                      autoPlay
                      interval={2000}
                      showStatus={false}
                      showArrows={false}
                      showThumbs={false}
                    >
                      {articles.map((article) => (
                        <div key={article.id}>
                          <h1>{article.title}</h1>
                          <a href={`#/article/${article.id}`}>Voir plus</a>
                        </div>
                      ))}

                      
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="trend spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="trend__content">
                <div className="section-title">
                  <h4>TENDANCE</h4>
                </div>

                {list
                  .filter((p) => p.type === "tendance")
                  .slice(0, 3)
                  .map((p, index) => (
                    <Trend
                      index={index}
                      titre={p.nom}
                      rating={p.rating}
                      prix={p.prix}
                      img={p.img1}
                      fin={p.date_fin}
                      deb={p.date_deb}
                      promo={p.promo}
                      stock={p.stock}
                      prodId={p.id}
                    />
                  ))}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="trend__content">
                <div className="section-title">
                  <h4>Best seller</h4>
                </div>
                {list
                  .filter((p) => p.type === "best_seller")
                  .slice(0, 3)
                  .map((p, index) => (
                    <Trend
                      index={index}
                      titre={p.nom}
                      rating={p.rating}
                      prix={p.prix}
                      fin={p.date_fin}
                      deb={p.date_deb}
                      img={p.img1}
                      promo={p.promo}
                      stock={p.stock}
                      prodId={p.id}
                    />
                  ))}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="trend__content">
                <div className="section-title">
                  <h4>RECOMMANDÉ</h4>
                </div>
                {list
                  .filter((p) => p.type === "recommande")
                  .slice(0, 3)
                  .map((p, index) => (
                    <Trend
                      index={index}
                      titre={p.nom}
                      rating={p.rating}
                      fin={p.date_fin}
                      deb={p.date_deb}
                      prix={p.prix}
                      img={p.img1}
                      promo={p.promo}
                      stock={p.stock}
                      prodId={p.id}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="discount">
        <div className="container">
          <Carousel
            infiniteLoop={true}
            autoPlay
            interval={5000}
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            swipeable={!isMobile}
          >
            {produitpromo.map((prod, index) => (
              <div className="row" id="divTarget">
                <div
                  className="col-lg-6 p-0"
                  style={{ backgroundColor: "white" }}
                >
                  <center>
                    <div
                      className="discount__pic"
                      style={{ width: "360px", height: "390px" }}
                    >
                      <Link
                        to={`../product/${prod.id}`}
                        className="image-popup"
                        style={{}}
                      >
                        <img
                          src={`https://veracoslab.com/API/public/uploads/${prod.img1}`}
                          alt={prod.img1_titre}
                          description={prod.img1_description}
                        />
                      </Link>
                    </div>
                  </center>
                </div>
                <div className="col-lg-6 p-0">
                  <Link
                    to={`../product/${prod.id}/${prod.nom.replace(/ /g, "-")}`}
                    className="image-popup"
                  >
                    <div className="discount__text">
                      <div className="discount__text__title">
                        <span>Promotions</span>
                        <h2>{prod.nom}</h2>
                        <h3 style={{ color: "#041014" }}>
                          <b>{prod.promo} %</b>
                        </h3>
                      </div>
                      <CountdownTimer
                        targetDate={new Date(prod.date_fin).getTime()}
                      />
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      <section className="services spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="services__item">
                <i className="fa fa-car" />
                <h6>Livraison express</h6>
                <p>
                  Assurance de recevoir les produits rapidement et en bon état
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="services__item">
                <i className="fa fa-money" />
                <h6>Promotions exceptionnelles</h6>
                <p>
                  Mise en avant régulière de promotions et de remises exclusives
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="services__item">
                <i className="fa fa-support" />
                <h6>Livraison Gratuite </h6>
                <p>Pour toute commande supérieure à 99 TND</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="services__item">
                <i className="fa fa-headphones" />
                <h6>Service client réactif</h6>
                <p>Disponibilité par e-mail, téléphone ou chat en direct</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
