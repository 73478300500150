import React from 'react'
import { useEffect, useState, useRef } from "react";
import { Helmet } from 'react-helmet-async';
const Contact = () => {

  useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute('content', "Prenez contact avec nous pour toute question, suggestion ou demande d'assistance. Notre équipe dévouée est là pour répondre à vos besoins. Utilisez nos coordonnées pour nous joindre facilement. Nous sommes impatients de vous aider et de rendre votre expérience shopping aussi agréable que possible. N'hésitez pas à nous contacter, nous sommes à votre disposition !");
  }, []);

  return (
    <>
       <Helmet>
    
        <meta name="description" content="Prenez contact avec nous pour toute question, suggestion ou demande d'assistance. Notre équipe dévouée est là pour répondre à vos besoins. Utilisez nos coordonnées pour nous joindre facilement. Nous sommes impatients de vous aider et de rendre votre expérience shopping aussi agréable que possible. N'hésitez pas à nous contacter, nous sommes à votre disposition !"/>
 
         </Helmet>
      <>
  <div className="breadcrumb-option">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="breadcrumb__links">
            <a href="/">
              <i className="fa fa-home" /> Home
            </a>
            <span>Contact</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Breadcrumb End */}
  {/* Contact Section Begin */}
  <section className="contact spad">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="contact__content">
            <div className="contact__address">
              <h5>Informations de contact</h5>
              <ul>
                <li>
                  <h6>
                    <i className="fa fa-map-marker" /> Adresse
                  </h6>
                  <p>
                  04 Rue Bir Mchergua, Borj Louzir Ariana
                  </p>
                </li>
                <li>
                  <h6>
                    <i className="fa fa-phone" /> Téléphone
                  </h6>
                  <p>
                 
                    <span>51 452 500</span>
                    <span>31 400 700</span>
                  </p>
                </li>
                <li>
                  <h6>
                    <i className="fa fa-headphones" /> Service client
                  </h6>
                  <p>contact@veracoslab.com</p>
                </li>
              </ul>
            </div>
            <div className="contact__form">
              <h5>CONTACTEZ-NOUS</h5>
              <form action="">
                <input type="text" placeholder="Nom & Prenom" />
                <input type="text" placeholder="Email" />
              
                <textarea placeholder="Message" defaultValue={""} />
                <button type="submit" className="site-btn">
                  ENVOYER
                </button>
              </form>
            </div>
          </div>
        </div>
        
        <div className="col-lg-6 col-md-6">
          <div className="contact__map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.8882682486624!2d10.206896375643067!3d36.869093163813375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2cb10d6212751%3A0xc7391785f1c0c8e8!2sVeracos!5e0!3m2!1sfr!2stn!4v1689994845301!5m2!1sfr!2stn"
              height={780}
              style={{ border: 0 }}
              allowFullScreen=""
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

    </>
  )
}

export default Contact
