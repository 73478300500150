import React from 'react'
import { Helmet } from 'react-helmet-async';
const Not_found = () => {
  return (
    <div style={{display:"flex",justifyContent:"center",marginTop:"50px",marginBottom:"50px"}}>
       <h1>404 PAGE NOT FOUND</h1>
    </div>
  )
}

export default Not_found
