import React from 'react'
import { Helmet } from 'react-helmet-async';
const commandes = () => {
  return (
    <div>
        <Helmet>
     
        <meta name="description" content="Veracos. Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté."/>
 
         </Helmet>
      <h1>commandes</h1>
    </div>
  )
}

export default commandes
