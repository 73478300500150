import React from "react";
import { useEffect, useState,useRef } from "react";
import { Navigate, useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, refreshUser } from "../actions/auth";
import axios from "axios";
import Cartitem from "./components/cart_item";
import { useCookies } from "react-cookie";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  addCodePromo,
  deleteCodePromo,
  addRemise,
  deleteRemise,
  addLivraison,
  deleteLivraison,
  addTotalAvant,
  deleteTotalAvant,
  addTotalApres,
  deleteTotalApres,
} from './../actions/action';
const Cart = () => {
  const [cookies, setCookie] = useCookies();
  const { user: currentUser } = useSelector((state) => state.auth);
  const cartItems = useSelector((state) => state.cart.items);
  const [mycart, setMycart] = useState([]);
  const [codePromo, setCodePromo] = useState('');
  const [promoinvalide, setPromoinvalide] = useState(false);
  const [promomsg, setPromomsg] = useState('');
  const [livraison, setLivraison] = useState(false);
  const [remise, setRemise] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleButtonClick = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    
    try {

    
      const response = await axios.post(`/promotionsbycode/${codePromo}`);
      
     
      if(response.status===200)
      {
        setPromoinvalide(false)
        
        
        setPromoinvalide(false)
        const promo = response.data;
        const startDate = new Date(promo.date_deb);
        const endDate = new Date(promo.date_fin);
        const currentDate = new Date();
        const isBetweenDates = currentDate >= startDate && currentDate <= endDate;

         if (promo.nbr_utilisation < 1 )
         {
          setPromoinvalide(true)
          setPromomsg("Nombre d'utilisation dépassé!")
         }
         
          if(isBetweenDates===false){
          
          setPromomsg("Code promo expiré!")
          setPromoinvalide(true)
         }
         

         if(promo.depense_min > total_avant)
          {
            setPromoinvalide(true)
            setPromomsg("le total de votre commande doit dépasser "+total_avant+" TND pour utiliser ce code!")
          }
         
          if (isBetweenDates && parseInt(promo.nbr_utilisation) > 0 && parseInt(promo.depense_min) < total_avant)

         {
         
            setCodePromo(promo.code)
            dispatch(addCodePromo(promo.code));

            if(promo.type==="Somme")
          {
            if(promo.livraison_gratuite==="0" && total_avant< 100)
            {
              setTotal_apres((total_avant-parseFloat(promo.value, 10))+7);
              dispatch(addTotalApres((total_avant-parseFloat(promo.value, 10))+7));
            }
           
            else {
              setTotal_apres(total_avant-parseFloat(promo.value, 10));
              dispatch(addTotalApres(total_avant-parseFloat(promo.value, 10)));

            }
           
           
            setRemise(parseFloat(promo.value, 10))
            dispatch(addRemise(parseFloat(promo.value, 10)));
            dispatch(addTotalAvant(total_avant));
           
          }

          if(promo.type==="Pourcentage")
          {

            if(promo.livraison_gratuite==="0" && total_avant< 100)
            {
              setTotal_apres((total_avant- ((parseFloat(promo.value, 10) / 100) * total_avant))+7);
              dispatch(addTotalApres((total_avant- ((parseFloat(promo.value, 10) / 100) * total_avant))+7));
            }
           
            else
            {
              setTotal_apres(total_avant- ((parseFloat(promo.value, 10) / 100) * total_avant));
              dispatch(addTotalApres())
            }
          
            setRemise(((parseFloat(promo.value, 10) / 100) * total_avant))
            dispatch(addRemise(((parseFloat(promo.value, 10) / 100) * total_avant)));
            dispatch(addTotalAvant(total_avant));
            dispatch(addTotalApres(total_avant- ((parseFloat(promo.value, 10) / 100) * total_avant)));
           

          }
          if(promo.livraison_gratuite==="1")
          {
            setLivraison(true)
            dispatch(addLivraison(1));
            // add to redux 1
          }
          else
          {
           
            setLivraison(false)
            dispatch(addLivraison(0));
            // add to redux 0
          }
         }
         dispatch(addTotalAvant(total_avant));
         dispatch(addTotalApres(total_apres));
      }
      else
      {
        setPromoinvalide(true)
        setPromomsg('code invalide!')
      }




      

     
    } catch (error) {
      console.log(error)
    
    }



    // You can perform any other actions with the codePromo value here
  };

  useEffect(() => {
    setMycart(cartItems)
  }, [cartItems]); // The effect will be triggered whenever cartItems changes



  useEffect(() => {
    dispatch(refreshUser(currentUser?.foundUser.id));
    dispatch(addCodePromo(""));
    dispatch(addRemise(0));
    dispatch(addLivraison(0));
    dispatch(addTotalAvant(0));
    dispatch(addTotalApres(0));
  }, []);





  const [list, setList] = useState([]);

  const [total_avant, setTotal_avant] = useState(0);
  const [total_apres, setTotal_apres] = useState(0);
  const [results, setResults] = useState([]);


 
  useEffect(() => {
    let calculatedTotal = 0;

    for (const item of cartItems) {
      let itemPrice = item.prix;

    

      calculatedTotal += item.quantity * itemPrice;
    }
    
    setTotal_avant(calculatedTotal);
    
    setTotal_apres(calculatedTotal);
    dispatch(addTotalApres(calculatedTotal));
    if(livraison ===false && calculatedTotal <100)
    {
      setTotal_apres(calculatedTotal+7);
      dispatch(addTotalApres(calculatedTotal+7));
    }
    

    dispatch(addTotalAvant(calculatedTotal));
    
    

  }, [cartItems]);

 

 
  

  return (
    <>
      <Helmet>
         <meta name="description" content="Veracos. Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté."/>
 
         </Helmet>
      <>
        <div className="breadcrumb-option">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb__links">
                  <a href="/">
                    <i className="fa fa-home" />
                    home
                  </a>
                  <span>Shopping cart</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Breadcrumb End */}
        {/* Shop Cart Section Begin */}
        <section className="shop-cart spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="shop__cart__table">
                  <table>
                    <thead>
                      <tr>
                        <th>Produit</th>
                        <th>Prix</th>
                        <th>Quantité</th>
                        <th>Total</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {mycart.map((prod,index) => (
                        <Cartitem
                          // Make sure to provide a unique key prop
                          childId={prod.id}
                          key={prod.id}
                          prodid={prod.id}
                          img={prod.img}
                          nom={prod.titre}
                          promo={prod.promo}
                          rating={prod.rating}
                          prix={`${prod.prix}`}
                        
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="cart__btn">
                  <a href="#/shop">Continuer vos achats</a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6"></div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="discount__content">
                  <h6>Code Promo</h6>
                  <form action="#">
                  <input
        type="text"
        value={codePromo}
        onChange={(e) => setCodePromo(e.target.value)} // Update state when input changes
        placeholder="Enter votre code promo "
      />
                    <button onClick={handleButtonClick} className="site-btn">
                      Appliquer
                    </button>
                  </form>
                  {promoinvalide && (  <p style={{color:'#ca1515'}}>{promomsg}</p>) }
                
                </div>
              </div>
              <div className="col-lg-4 offset-lg-2">
                <div className="cart__total__procced">
                  <h6>Montant du panier</h6>
                  <hr/>
                  <ul>
                    {livraison && ( <li>
                     <p>
                          Livraison <b>gratuite</b> grâce au code promotionnel</p>
                          <hr/>
                    </li>) }

                  
                 
                    {(codePromo!=="" && promoinvalide===false) && ( <li>
                     <p>
                         code promo : {codePromo} - {remise} TND</p>
                          <hr/>
                    </li>) }

                    <li>
                  
                  Sous total<span>{total_avant} TND</span>
                </li>
                {(livraison===false && total_avant < 100) ? ( <li>
                     <p>
                          Frais de livraison <b>7 TND</b> ( commande inférieure a 99 TND</p>
                          <hr/>
                    </li>): (<li>
                     <p>
                     Livraison <b>gratuite</b> (commande supérieure à 99 TND)</p>
                          <hr/>
                    </li>) }
               
                    
                  
                      
                        <li> Total a payer<span>{total_apres} TND</span></li>
                    
                  </ul>
                  <a href="#/billing" className="primary-btn">
                  Valider la commande

                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Cart;
