

// shopReducer.js
const initialState = {
    categories: [],
    sous_categories: [],
    min: 0,
    max: 9999,
  };
  
  const filtreReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_CATEGORY':
        const newCategory = action.payload;
        if (!state.categories.includes(newCategory)) {
          return {
            ...state,
            categories: [...state.categories, newCategory],
          };
        }
        return state;
  
      case 'REMOVE_CATEGORY':
        const categoryToRemove = action.payload;
        return {
          ...state,
          categories: state.categories.filter((category) => category !== categoryToRemove),
        };
  
      case 'ADD_SOUS_CATEGORIE':
        const newSousCategorie = action.payload;
        if (!state.sous_categories.includes(newSousCategorie)) {
          return {
            ...state,
            sous_categories: [...state.sous_categories, newSousCategorie],
          };
        }
        return state;
  
      case 'REMOVE_SOUS_CATEGORIE':
        const sousCategorieToRemove = action.payload;
        return {
          ...state,
          sous_categories: state.sous_categories.filter(
            (sousCategorie) => sousCategorie !== sousCategorieToRemove
          ),
        };
  
      case 'UPDATE_MIN_MAX':
        const { min, max } = action.payload;
        return {
          ...state,
          min,
          max,
        };
  
      default:
        return state;
    }
  };
  
  export default filtreReducer;