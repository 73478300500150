import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  REFRESH_USER_SUCCESS,
} from "./types";

import axios from "axios";
axios.defaults.baseURL = "http://localhost:5000";

const loginFunction = (email, password) => {
  return axios
    .post(`/auth/login`, {
      email,
      password,
    
    })
    .then((response) => {
      if (response.data.accessToken) {
        
        localStorage.setItem("user", JSON.stringify({...response.data,roles:null}));
      }

      return response.data;
    });
};

const logoutFunction = () => {
  localStorage.removeItem("user");
};

const updateFunction = (user) => {
  return axios
    .put(`http://localhost:5000/modifyProfile/${user.id}`, user, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log("response de modif " + response);
      if (response.data.accessToken) {
        console.log(response.data.accessToken);
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};




export const login = (username, password) => (dispatch) => {
  return loginFunction(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const logout = () => (dispatch) => {
  logoutFunction();
  dispatch({
    type: LOGOUT,
  });
};
export const modifyUser = (user) => (dispatch, getState) => {
  const currentUser = getState().auth.user;

  return updateFunction(user).then(
    (data) => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: { user: currentUser },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};


const getUser = (id) => {
  return axios
    .get(`http://localhost:5001/auth/getUser/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    });
};

export const refreshUser = (id) => (dispatch, getState) => {
  const currentUser = getState().auth.user;
  return Promise.resolve(currentUser);
 
};

export default {
  login,
  logout,
  modifyUser,

};
