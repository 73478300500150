import React from "react";
import { useEffect, useState, useRef } from "react";
import { Navigate, useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, refreshUser } from "../actions/auth";
import axios from "axios";
import { useCookies } from "react-cookie";
import { connect } from "react-redux";
import { resetCart } from "./../actions/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
} from "./../actions/action";
import { Helmet } from "react-helmet-async";
const Billing = () => {
  const [cookies, setCookie] = useCookies();
  const { user: currentUser } = useSelector((state) => state.auth);
  const cartItems = useSelector((state) => state.cart.items);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const getOrderCookies = () => {
    const orderCookies = Object.keys(cookies).filter((cookieName) =>
      cookieName.includes("order_")
    );
    return orderCookies.map((cookieName) => cookies[cookieName]);
  };



  const orderCookies = getOrderCookies();
  const newArray = orderCookies.map((item) => item.split("/"));

  const [userId, setUserId] = useState("");
  const [products, setProducts] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [ville, setVille] = useState("");
  const [notes, setNotes] = useState("");
  const [done, setDone] = useState(0);
  const [total, setTotal] = useState(0);
  const code_promo = useSelector((state) => state.cart.code_promo);
  const remise = useSelector((state) => state.cart.remise);
  const livraison = useSelector((state) => state.cart.livraison);
  const total_avant = useSelector((state) => state.cart.total_avant);
  const total_apres = useSelector((state) => state.cart.total_apres);
  const [mdp, setMdp] = useState("");
  const [errorregister, setErrorRegister] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const notify = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const notify_err = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleUserIdChange = (event) => {
    setUserId(event.target.value);
  };

  const handleProductsChange = (event) => {
    setProducts(event.target.value);
  };

  const handleNomChange = (event) => {
    setNom(event.target.value);
  };

  const handlePrenomChange = (event) => {
    setPrenom(event.target.value);
  };

  const handleAdresseChange = (event) => {
    setAdresse(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTelChange = (event) => {
    setTel(event.target.value);
  };

  const handleVilleChange = (event) => {
    setVille(event.target.value);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const checkPrices = async (data) => {
    try {
      let missingFields = false;
      let sumOfPrices = 0;

      // Create an array to hold the promises for fetching produit objects
      const fetchPromises = data.map(async (item) => {
        // Check for missing or empty fields
        if (
          !item.id ||
          !item.titre ||
          !item.img ||
          !item.prix ||
          !item.promo ||
          !item.quantity
        ) {
          missingFields = true;
          dispatch(removeFromCart(item.id));
          return false;
        }

        // Make the API call to fetch the produit object from the server
        const response = await axios.get(`/produits/${item.id}`);
        if (!response.data.status === 200) {
          throw new Error(`Failed to fetch produit for item ${item.id}`);
        }
        const produit = response.data;

        // Retrieve the price from the produit object
        let itemPrice = parseFloat(produit.prix);

        // Check if today's date is between produit.date_deb and produit.date_fin
        const today = new Date();
        const dateDeb = new Date(produit.date_deb);
        const dateFin = new Date(produit.date_fin);
        const isWithinDateRange = today >= dateDeb && today <= dateFin;
        if (produit.promo !== "0" && isWithinDateRange) {
          const discountPercentage = parseFloat(produit.promo);
          const discountedPrice =
            itemPrice - itemPrice * (discountPercentage / 100);
          itemPrice = discountedPrice;
        }

        // Add item price to sum
        sumOfPrices += itemPrice * item.quantity;

        // Perform checks for all fields
        const fetchedPrice = parseFloat(item.prix); // Assuming fetched price is same as item price
        const isPriceCorrect = itemPrice === fetchedPrice;
        const isQuantityCorrect = item.quantity > 0;

        if (!(isPriceCorrect && isQuantityCorrect)) {
          dispatch(removeFromCart(item.id));
        }
        return isPriceCorrect && isQuantityCorrect;
      });

      // Wait for all fetch promises to resolve
      const fetchedProduits = await Promise.all(fetchPromises);

      // Check if any field is missing or empty
      if (missingFields) {
        return false;
      }

      // Check if all checks are true
      const priceCheck = fetchedProduits.every((check) => check === true);

      // Compare sumOfPrices with total_avant
      const isPriceSumCorrect = sumOfPrices === total_avant;

      // Return true only if all checks are true
      return priceCheck && isPriceSumCorrect;
    } catch (error) {
      console.error("Error checking prices:", error);
      return false;
    }
  };

  const handleCreateCommande = async (e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        return await checkPrices(cartItems);
      } catch (error) {
        console.error("Error checking prices:", error);
        return false; // Return false in case of error
      }
    };

    const result = await fetchData();

    if (result === false) {
      notify_err(
        "Oops !cette commande n'a pas pu être acceptée. Vous serez redirigé vers votre panier. Veuillez vérifier que tous vos articles sont là."
      );
      const timer = setTimeout(() => {
        // Redirect to the "/cart" path after 3 seconds
        navigate("/cart");
      }, 3000);
    } else if (result === true) {
      setIsLoading(true);

      setTimeout(async () => {
        const exportObject = {
          dataArray: cartItems,
          code_promo: code_promo,
          remise: remise,
          livraison: livraison,
          total_avant: total_avant,
          total_apres: total_apres,
          // Add more extra variables as needed
        };

        try {
          if (isChecked) {
            if (
              nom !== "" &&
              prenom !== "" &&
              adresse !== "" &&
              email !== "" &&
              tel !== "" &&
              mdp !== ""
            ) {
              // Create an object with the registration data
              const data = {
                nom,
                prenom,
                adresse,
                email: email,
                tel,
                role: "user",
                code_promo: "",
                mdp,
                // ... other fields if needed
              };

              // Send the POST request to the backend
              axios
                .post("/signup", data)

                .then(async (response1) => {
                  setUserId(response1.data.userId);
                  const commandeData = {
                    user_id: response1.data.userId,
                    products: exportObject,
                    nom,
                    prenom,
                    adresse,
                    email,
                    tel,
                    ville,
                    notes,
                    etat: "0",
                    total: total_apres,
                  };
                  const response2 = await axios.post("/send-email-inscri", {
                    email: email,
                    nom: prenom,
                  });

                  const response = await axios.post("/commandes", commandeData);

                  const response3 = await axios.post("/send-email-commande", {
                    email: email,
                    nom: prenom,
                    date: response.data.createdAt,
                    total: total_apres,
                    id: response.data.id,
                  });
              

                  ///setDone(1);
                  navigate("/success");
                  notify("commande acceptée");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Add smooth behavior for a smooth scroll effect
                  });

                  try {
                    if(code_promo !=="")
                    {
                      const response2 = await axios.post(
                        `/promotions/decrement`,
                        {
                          code: code_promo,
                        }
                      );
                    }
                  
                  } catch (error) {
                    console.error("Error updating promotion:", error.message);
                  }

                  dispatch(resetCart());
                  // Handle successful response
                  // send mail here
                })
                .catch((error) => {
                  // Handle error response
                  console.error(error.response.data.error);
                  setErrorRegister(error.response.data.error);

                  // You can display an error message or perform any other error handling here
                });
            }
          } else {
            if (
              nom !== "" &&
              prenom !== "" &&
              adresse !== "" &&
              email !== "" &&
              tel !== "" &&
              ville !== ""
            ) {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (emailRegex.test(email) === false) {
                notify_err(
                  "Veuillez vérifier que vous avez tapé un e-mail correct."
                );
              } else {
                const commandeData = {
                  user_id: userId,
                  products: exportObject,
                  nom,
                  prenom,
                  adresse,
                  email,
                  tel,
                  ville,
                  notes,
                  etat: "0",
                  total: total_apres,
                };

                const response = await axios.post("/commandes", commandeData);
                const dataArray = response.data.products.dataArray;

                const transformedArray = dataArray.map((item) => ({
                  name: item.titre,
                  quantity: item.quantity,
                  price: item.prix,
                  sku: "", // You can set this value as needed
                  product_id: item.id,
                  tax: 0, // You can set this value as needed
                  discount: 0,
                }));
                // GOAFFPRO start
                window.goaffpro_order = {
                  id: response.data.id, // the internal order ID of your system (useful if you are using REST JSON file)
                  number: response.data.id, // human readable order number displayed to the admins
                  total: response.data.products.total_apres, // the order total (the final amount that the customer paid)
                  subtotal: response.data.products.total_apres, // order subtotal (order total minus shipping and taxes)
                  discount: 0, // the discount received by the customer
                  
                  customer: {
                    // customer details
                    first_name: response.data.prenom,
                    last_name: response.data.nom,
                    email: response.data.email,
                    phone: response.data.tel, // optional
                    is_new_customer: true, //optional
                  },
                  coupons: [], // an array of discount codes applied to the order
                  line_items: transformedArray,
                  status: "approved",
                  delay: 60000, // the amount in milliseconds to delay the processing of this order. This is use full for up-sell where the second conversion event should take preference over the first order. So send the first conversion event with a suitable delay parameter
                };
                if (typeof window.goaffproTrackConversion !== "undefined") {
                  window.goaffproTrackConversion(window.goaffpro_order);
                }
                // GOAFFPRO end
                const response3 = await axios.post("/send-email-commande", {
                  email: email,
                  nom: prenom,
                  date: response.data.createdAt,
                  total: total_apres,
                  id: response.data.id,
                });

                //setDone(1);
                navigate("/success");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Add smooth behavior for a smooth scroll effect
                });

                try {
                  const response2 = await axios.post(`/promotions/decrement`, {
                    code: code_promo,
                  });
                } catch (error) {
                  console.error("Error updating promotion:", error.message);
                }

                dispatch(resetCart());
              }
            } else notify_err("Merci de remplir tous les champs obligatoires!");
          }
        } catch (error) {
          console.error("Error creating commande:", error.response.data.error);
        }
        setIsLoading(false);
      }, 5000);
    }
  };
  /*
  const handleRegister = () => {
    // Perform validation if needed

    if (
      nom !== "" &&
      prenom !== "" &&
      adresse !== "" &&
      email !== "" &&
      tel !== "" &&
      mdp !== ""
    ) {
      // Create an object with the registration data
      const data = {
        nom,
        prenom,
        adresse,
        email: email,
        tel,
        role: "user",
        code_promo: "",
        mdp,
        // ... other fields if needed
      };

      // Send the POST request to the backend
      axios
        .post("/signup", data)

        .then((response) => {
         
          setUserId(response.data.userId)
          // Handle successful response
          // send mail here
        })
        .catch((error) => {
          // Handle error response
          console.error(error.response.data.error);
          setErrorRegister(error.response.data.error);

          // You can display an error message or perform any other error handling here
        });
    }
  };
*/
  useEffect(() => {
    dispatch(refreshUser(currentUser?.foundUser.id));
    setUserId(currentUser?.foundUser.id);
    if (currentUser?.foundUser) {
      setNom(currentUser?.foundUser.nom);
      setPrenom(currentUser?.foundUser.prenom);
      setAdresse(currentUser?.foundUser.adresse);
      setEmail(currentUser?.foundUser.email);
      setTel(currentUser?.foundUser.tel);
    }
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const calculateItemTotal = (item) => {
    let newPrice = parseFloat(item.prix);

    return newPrice * item.quantity;
  };

  const createItemsArray = () => {
    let total = 0;
    const itemList = cartItems.map((item) => {
      const itemTotal = calculateItemTotal(item);
      total += itemTotal;
      return {
        name: item.titre,
        quantity: item.quantity,
        total: parseFloat(itemTotal.toFixed(2), 10), // Round to 2 decimal places (optional)
      };
    });
    return { itemList, total: total.toFixed(2) }; // Return an object containing the array and total
  };

  useEffect(() => {
    const { itemList, total } = createItemsArray();
    setList(itemList);

    let x = parseFloat(total, 10);
    if (remise !== 0) x = x - remise;

    if (livraison !== 1 && total < 100) x = x + 7.0;

    setTotal(x);
  }, [cartItems]);

  const [list, setList] = useState([]);
  const loadingBarStyle = {
    border: "4px solid rgba(0, 123, 255, 0.3)", // Border color and transparency
    borderTop: "4px solid #007bff", // Top border color
    borderRadius: "50%", // Makes the element round
    width: "40px", // Width of the spinner
    height: "40px", // Height of the spinner
    animation: "spin 1s linear infinite",
    position: "fixed", // Positioning
    top: "50%", // Center vertically
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)", // Center the element // Animation for spinning
  };
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Veracos. Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté."
        />
      </Helmet>
      <section className="checkout spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h6 className="coupon__link">
                C'est un plaisir de vous accueillir sur notre site ! Nous
                espérons que vous apprécierez votre expérience parmi nous.
              </h6>
            </div>
          </div>
          {done === 0 && (
            <form action="#" className="checkout__form">
              <div className="row">
                <div className="col-lg-8">
                  <h5>Détails de facturation</h5>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="checkout__form__input">
                        <p>
                          Nom <span>*</span>
                        </p>
                        <input
                          type="text"
                          value={nom}
                          onChange={(e) => setNom(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="checkout__form__input">
                        <p>
                          Prenom <span>*</span>
                        </p>
                        <input
                          type="text"
                          value={prenom}
                          onChange={(e) => setPrenom(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="checkout__form__input">
                        <p>
                          Adresse <span>*</span>
                        </p>
                        <input
                          type="text"
                          placeholder="Adresse de livraison"
                          value={adresse}
                          onChange={(e) => setAdresse(e.target.value)}
                        />
                      </div>
                      <div className="checkout__form__input">
                        <p>
                          Ville <span>*</span>
                        </p>
                        <input
                          type="text"
                          value={ville}
                          onChange={(e) => setVille(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="checkout__form__input">
                        <p>
                          Téléphone <span>*</span>
                        </p>
                        <input
                          type="text"
                          value={tel}
                          onChange={(e) => setTel(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="checkout__form__input">
                        <p>
                          Email <span>*</span>
                        </p>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      {userId === "" && (
                        <>
                          {" "}
                          <div className="checkout__form__checkbox">
                            <label htmlFor="acc">
                              Créer un compte ?
                              <input
                                type="checkbox"
                                id="acc"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                              <span className="checkmark" />
                            </label>
                            <p>
                              Créez un compte en saisissant les informations
                              ci-dessous. Si vous avez déjà un compte ,
                              connectez-vous en haut de la page
                            </p>
                          </div>
                          <div className="checkout__form__input">
                            <p>
                              Mot de passe du compte <span>*</span>
                            </p>
                            <input
                              type="password"
                              value={mdp}
                              onChange={(event) => setMdp(event.target.value)}
                            />
                          </div>
                        </>
                      )}

                      <div className="checkout__form__checkbox"></div>
                      <div className="checkout__form__input">
                        <p>Notes commande</p>
                        <input
                          type="text"
                          placeholder=" Remarque concernant votre commande, par exemple, note spéciale pour la livraison"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="checkout__order">
                    <h5>Votre Commande</h5>
                    <div className="checkout__order__product">
                      <ul>
                        <li>
                          <span className="top__text">Produits</span>
                          <span className="top__text__right">Total</span>
                        </li>
                        {list.map((item, index) => (
                          <li>
                            {item.quantity} * {item.name}{" "}
                            <span>{item.total} TND</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {code_promo !== "" && (
                      <div className="checkout__order__total">
                        <p>CODE PROMO : </p>
                        <ul>
                          <li>
                            Code : <span> {code_promo} </span>
                          </li>
                          <li>
                            Remise : <span>- {remise} TND</span>
                          </li>
                          {livraison === 1 && (
                            <li>
                              <p>
                                {" "}
                                livraison <b>gratuite</b>
                              </p>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}

                    {livraison !== 1 && (
                      <>
                        {" "}
                        {total < 100 && (
                          <p>
                            {" "}
                            frais de livraison <b>7 TND</b>
                          </p>
                        )}
                        {total > 99 && (
                          <p>
                            {" "}
                            livraison <b>gratuite</b> (commande supérieure à 99
                            TND)
                          </p>
                        )}
                        <hr />
                      </>
                    )}

                    {total_avant !== 0 && (
                      <div className="checkout__order__total">
                        <ul>
                        {code_promo !== "" ? ( <li>
                            Sous-Total <span> {total_avant-remise} TND</span>
                          </li>):( <li>
                            Sous-Total <span> {total_avant} TND</span>
                          </li>)}
                         
                        </ul>
                      </div>
                    )}
                    <div className="checkout__order__total">
                      <ul>
                      {code_promo !== "" ? ( <li>
                            Total <span> {total_apres-remise} TND</span>
                          </li>):( <li>
                            Total <span> {total_apres} TND</span>
                          </li>)}
                      </ul>
                    </div>
                    <p>
                      <b>Paiement à la livraison</b>
                      <br />
                      Payer en argent comptant à la livraison{" "}
                    </p>
                    <hr />
                    <p>
                      Vos données personelles seront utilisées pour le
                      traitement de votre commande, vous accompagner au cours de
                      votre visite du site web, et pour d'autres raisons
                      décrites dans notre{" "}
                      <Link to={`../politique`}>
                        politique de confidentialité
                      </Link>
                    </p>
                    <div className="checkout__order__widget"></div>
                    {isLoading ? (
                      <>
                        {" "}
                        <style>
                          {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); } /* Full rotation */
          }
        `}
                        </style>
                        <div style={loadingBarStyle}></div>
                      </>
                    ) : (
                      <button
                        className="site-btn"
                        onClick={handleCreateCommande}
                      >
                        {isLoading
                          ? "Commande en cours de création..."
                          : "Commandez"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}

          {done === 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {errorregister !== "" && (
                <p style={{ color: "red" }}>
                  En raison d'un problème, votre compte n'a pas été créé,
                  veuillez vous inscrire en utilisant le bouton en haut à droite
                  <br>Message d'erreur : {errorregister}</br>
                </p>
              )}

              <h5>
                Félicitations ! Votre commande a été enregistrée avec succès.
                Notre équipe du service client prendra contact avec vous dans
                les meilleurs délais pour confirmer les détails.
                <br />
                <br />
                Bienvenue dans notre communauté de clients heureux !<br /> Merci
                d'avoir choisi notre boutique !<br /> Pour suivre toutes nos
                actualités, rejoignez-nous sur les réseaux sociaux !<br />
                <br />{" "}
                <div className="footer__social">
                  <a target="_blank" href="https://www.facebook.com/veracoslab">
                    <i
                      className="fa fa-facebook"
                      href="https://www.facebook.com/veracoslab"
                    />
                  </a>

                  <a
                    target="_blank"
                    href="https://www.instagram.com/veracoslab/"
                  >
                    <i className="fa fa-instagram" />
                  </a>

                  <a target="_blank" href="https://www.tiktok.com/@veracoslab">
                    <i className="fa fa-music" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCP5kdgAQge03F1zjHJ6YtMg"
                  >
                    <i className="fa fa-youtube" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.pinterest.fr/veracoslab/"
                  >
                    <i className="fa fa-pinterest" />
                  </a>
                </div>
              </h5>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default Billing;
