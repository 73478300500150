import axios from "axios";
import { useEffect, useState, useRef } from "react";

import { connect } from 'react-redux';
import Produit from "./components/produit_component";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
const Search = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [listProd, setListProd] = useState([]);
  const [list, setList] = useState([]);

  const [activeCollapse, setActiveCollapse] = useState("");
  const [visibleItems, setVisibleItems] = useState(8);
  const lastVisibleElementRef = useRef(null);

  const { type, id,nom,titre } = useParams();
  const location = useLocation();




useEffect(() => {

  async function getListProdFromAPI() {
    try {
      if(location.pathname==="/shop")
      {
        const response = await axios.post('/produits/filtred/all/0', {     
        }); 
        setListProd(response.data);
      }
      else
      {
        const response = await axios.post('/produits/filtred/'+type+'/'+id, {     
        }); 
        setListProd(response.data);
      }

      // Assuming the response data is an array, update the state with the list
      
      
    } catch (error) {
      console.error("Error retrieving the list:", error);
    }
  }

  // Call the function to fetch the list from the API
  getListProdFromAPI();


}, [id]);


  useEffect(() => {
    async function getListFromAPI() {
      try {
        const response = await axios.post("/categories/withsous");
        setList(response.data);
       
      } catch (error) {
        console.error("Error retrieving the list:", error);
      }
    }

    getListFromAPI();
  }, []);

  const handleCollapseToggle = (index) => {
    setActiveCollapse(index === activeCollapse ? "" : index);
  };

  // Function to handle scroll event
  const handleScroll = () => {
    if (lastVisibleElementRef.current) {
      const { top } = lastVisibleElementRef.current.getBoundingClientRect();
      if (top <= window.innerHeight) {
        // Load more items when the last visible element is in view
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 4);
      }
    }
  };

  // Attach scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);






      

  return (
    <>
      <Helmet>
    
    <meta name="description" content="Explorez notre boutique en ligne de cosmétiques et découvrez une gamme exquise de produits de beauté. Trouvez les dernières tendances en matière de maquillage, soins de la peau et bien plus encore. Sublimez votre beauté naturelle avec nos produits de qualité. Faites l'expérience de la sophistication et de l'élégance avec nos cosmétiques en ligne."/>

     </Helmet>
      <>
        <div className="breadcrumb-option">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb__links">
                  <a href="/">
                    <i className="fa fa-home" /> Home
                  </a>
                  <span>Nos Produits</span>
                
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Breadcrumb End */}
        {/* Shop Section Begin */}
        <section className="shop spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3">
              {isMobile===false && (<div className="shop__sidebar">
                  <div className="sidebar__categories">
                    <div className="section-title">
                      
                      <h4>Categories</h4>
                    </div>
                   
                    <div className="categories__accordion">
                      <div className="accordion" id="accordionExample">
                        {list.map((cat, index) => (
                          <div className="card" key={index}>
                            <div
                              className={`card-heading ${
                                activeCollapse === index ? "active" : ""
                              }`}
                            >
                                <a
                                data-toggle="collapse"
                                data-target={`#collapse${index}`}
                                onClick={() => handleCollapseToggle(index)}
                              >
                               <Link  to={"/shop/categorie/"+cat.id+"/"+cat.nom}><span >{cat.nom}</span></Link> 
                                    </a>
                              
                              
                          
                            </div>
                            <div
                              id={`collapse${index}`}
                              className={`collapse ${
                                index === activeCollapse ? "show" : ""
                              }`}
                              data-parent="#accordionExample"
                            >
                              <div className="card-body">
                                <ul>
                                  {cat.sousCategories.map(
                                    (souscat, subIndex) => (
                                      <li key={subIndex}>
                                        <Link  to={"/shop/sous_categorie/"+souscat.id+"/"+souscat.nom}><span >{souscat.nom}</span></Link> 
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                 
                </div>)}
                
              </div>
              <div className="col-lg-9 col-md-9">
              {id && (<span><b>{listProd.length}</b> résultats pour la {type==="categorie" ? "catégorie" : "sous catégorie" } {nom}</span>)}
                <div className="row">
                 
                  {listProd.filter((prod) => prod.nom.toLowerCase().includes(titre.toLowerCase())).slice(0, visibleItems).map((prod, index) => {
                    if (index === visibleItems - 1) {
                      return (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 mix women"
                          key={index}
                          ref={lastVisibleElementRef}
                        >
                              <Produit
                  prodId={prod.id}
                  img_url={`https://veracoslab.com/API/public/uploads/${prod.img1}`}
                  titre_img={prod.img1_titre}
                  description_img={prod.img1_descrip}
                  titre={prod.nom}
                  promo={prod.promo}
                  stock={prod.stock}
                  deb={prod.date_deb}
                  fin={prod.date_fin}
                  rating={prod.rating}
                  prix={`${prod.prix}`}
                />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 mix women"
                          key={index}
                        >
                              <Produit
                  prodId={prod.id}
                  img_url={`https://veracoslab.com/API/public/uploads/${prod.img1}`}
                  titre_img={prod.img1_titre}
                  description_img={prod.img1_descrip}
                  titre={prod.nom}
                  deb={prod.date_deb}
                  fin={prod.date_fin}
                  promo={prod.promo}
                  stock={prod.stock}
                  rating={prod.rating}
                  prix={`${prod.prix}`}
                />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Search;
