import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import draftToHtml from "draftjs-to-html";
const Article = () => {
  const [article, setArticle] = useState(null);
  const { id } = useParams(); // Assuming you're using React Router for routing

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch article data from your API based on the ID
        const response = await axios.get(`/articles/${id}`);
        const responseData = response.data;
    
        // Parse content strings to JSON
        const descriptionData = JSON.parse(responseData.description);
    
        // Convert description to HTML
        const descriptionHtml = draftToHtml(descriptionData);
        console.log(descriptionHtml);
        // Convert sections content to HTML if sections exist
        const sectionsWithHtml = responseData.Sections?.map((section) => {
          const contentData = JSON.parse(section.content);
          const contentHtml = draftToHtml(contentData);
          console.log(contentHtml);
          return {
            ...section,
            content: contentHtml,
          };
        }) || [];
    
        // Update article data with HTML content
        setArticle({
          ...responseData,
          description: descriptionHtml,
          sections: sectionsWithHtml,
        });
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };
    
  
    fetchData();
  
    // Cleanup function if needed
    return () => {
      // Cleanup code if any
    };
  }, [id]);
  
  



  return (
    <>
      <Helmet>
    
        <meta name="description" content="Veracos. Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté."/>
 
         </Helmet>
      <>
      {article!==null && (<>
      


        <div className="breadcrumb-option">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="breadcrumb__links">
            <a href="#/">
              <i className="fa fa-home" /> Home
            </a>
            <a href="#/articles">Blog</a>
            <span>
           {article.title}
       
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Breadcrumb End */}
  {/* Blog Details Section Begin */}
  <section className="blog-details spad">
    <div className="container">
      <div className="row">
      <div className="col-lg-2 col-md-2">
        </div>
    
        
          <div className="col-lg-12 col-md-12">
          <div className="blog__details__content">
            <div className="blog__details__item">
              <img     src={`https://veracoslab.com/API/public/uploads/${article.image}`} alt="" />
              <div className="blog__details__item__title">
                <span className="tip">By Veracos Lab</span>
                <h1>
                {article.title}
                </h1>
                
              </div>
            </div>
            <div className="blog__details__desc">
              <p    dangerouslySetInnerHTML={{ __html: article.description }} >
           
              </p>
       
            </div>
            {article.sections.map((section, index) => (
                     <>
                                 
            <div className="blog__details__quote">
              <div className="icon">
                <i className="fa fa-quote-left" />
              </div>
              <p>
              {section.title}
              </p>
            </div>

            <div className="blog__details__desc">
              <p  dangerouslySetInnerHTML={{ __html: section.content }}>
              </p>
            </div>
                     </>
                    ))}

  
           



          
       






     
           
          </div>
        </div>
        
        
      


      </div>
    </div>
  </section>
      </>)}

</>

    </>
  )
}

export default Article
