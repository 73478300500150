import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { incrementQuantity, decrementQuantity,removeFromCart } from './../../actions/action'; // Replace with the correct path to your action file

const Cart_item = (props) => {
  const { prodid, img, nom, prix, rating, promo, cartItems, incrementQuantity, decrementQuantity,removeFromCart } = props;
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);

  let newPrice = prix;

  const intValue = parseInt(rating, 10);
  useEffect(() => {
    if (Array.isArray(cartItems)) {
      // Check if cartItems is an array before using it
      const foundItem = cartItems.find((item) => item.id === prodid);
      setQuantity(foundItem ? foundItem.quantity : 0);
    }
  }, [cartItems, prodid]);

  useEffect(() => {
    // Recalculate total whenever quantity or newPrice (prix) changes
    setTotal(quantity * newPrice);
  }, [quantity, newPrice]);

  const handleIncrement = () => {
    props.incrementQuantity(prodid);
  };

  const handleDecrement = () => {
    props.decrementQuantity(prodid);
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setQuantity(inputValue);
  };

  const handleRemoveItem = () => {
    props.removeFromCart(prodid);
  };
  return (
    <tr>
           <td className="cart__product__item">
                    <img src={img} alt=""  style={{width:"75px"}}/>
                    <div className="cart__product__item__title">
                      <h6>{nom}</h6>
                      <div className="rating">
  {Array.from({ length: intValue }).map((_, index) => (
    <i key={index} className="fa fa-star" style={{ marginRight: "3px" }} />
  ))}
</div>
                    </div>
                  </td>
      <td className="cart__price">{newPrice} TND</td>
      <td className="cart__quantity">
        <div className="pro-qty">
          <span className="dec qtybtn" onClick={handleDecrement}><b>-</b></span>
          <input type="text" value={quantity} onChange={handleChange} />
          <span className="dec qtybtn" onClick={handleIncrement}><b>+</b></span>
        </div>
      </td>
      <td className="cart__total">{total} TND</td>
      <td className="cart__close">
        <span className="icon_close" onClick={handleRemoveItem}  id={prodid} />
      </td>
    </tr>
  );
};

// Connect the component to the Redux store
const mapStateToProps = (state) => ({
  // Add any state values you need to access from the Redux store as props
  cartItems: state.cart.items,
});

const mapDispatchToProps = {
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart_item);
