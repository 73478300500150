// actions.js
export const addToCart = (product) => ({
    type: 'ADD_TO_CART',
    payload: product,
  });
  
  export const removeFromCart = (productId) => ({
    type: 'REMOVE_FROM_CART',
    payload: productId,
  });
  
  export const incrementQuantity = (productId) => ({
    type: 'INCREMENT_QUANTITY',
    payload: productId,
  });
  
  export const decrementQuantity = (productId) => ({
    type: 'DECREMENT_QUANTITY',
    payload: productId,
  });

  // Action creators for code_promo
export const addCodePromo = (code_promo) => ({
  type: 'ADD_CODE_PROMO',
  payload: code_promo,
});

export const deleteCodePromo = () => ({
  type: 'DELETE_CODE_PROMO',
});

// Action creators for remise
export const addRemise = (remise) => ({
  type: 'ADD_REMISE',
  payload: remise,
});

export const deleteRemise = () => ({
  type: 'DELETE_REMISE',
});

// Action creators for livraison
export const addLivraison = (livraison) => ({
  type: 'ADD_LIVRAISON',
  payload: livraison,
});

export const deleteLivraison = () => ({
  type: 'DELETE_LIVRAISON',
});

// Action creators for total_avant
export const addTotalAvant = (total_avant) => ({
  type: 'ADD_TOTAL_AVANT',
  payload: total_avant,
});

export const deleteTotalAvant = () => ({
  type: 'DELETE_TOTAL_AVANT',
});

// Action creators for total_apres
export const addTotalApres = (total_apres) => ({
  type: 'ADD_TOTAL_APRES',
  payload: total_apres,
});

export const deleteTotalApres = () => ({
  type: 'DELETE_TOTAL_APRES',
});

export const resetCart = () => {
  return { type: "RESET_CART" };
};


  