import React from "react";


const Billing_success = () => {
  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    }}
  >

<br/>
<br/>
<br/>
              <h5 style={{marginTop:"100px",marginBottom:"100px"}}>
                Félicitations ! Votre commande a été enregistrée avec succès.
                Notre équipe du service client prendra contact avec vous dans
                les meilleurs délais pour confirmer les détails.
                <br />
                <br />
                Bienvenue dans notre communauté de clients heureux !<br /> Merci
                d'avoir choisi notre boutique !<br /> Pour suivre toutes nos
                actualités, rejoignez-nous sur les réseaux sociaux !<br />
                <br />{" "}
                <div className="footer__social">
                  <a target="_blank" href="https://www.facebook.com/veracoslab">
                    <i
                      className="fa fa-facebook"
                      href="https://www.facebook.com/veracoslab"
                    />
                  </a>

                  <a
                    target="_blank"
                    href="https://www.instagram.com/veracoslab/"
                  >
                    <i className="fa fa-instagram" />
                  </a>

                  <a target="_blank" href="https://www.tiktok.com/@veracoslab">
                    <i className="fa fa-music" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCP5kdgAQge03F1zjHJ6YtMg"
                  >
                    <i className="fa fa-youtube" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.pinterest.fr/veracoslab/"
                  >
                    <i className="fa fa-pinterest" />
                  </a>
                </div>
              </h5>
    </div>
  )
}

export default Billing_success
