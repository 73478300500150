import React from "react";
import { Helmet } from 'react-helmet-async';
const Politique = () => {
  return (
    <>
    <Helmet>
    
    <meta name="description" content="Veracos. Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté."/>

     </Helmet>
      <div className="breadcrumb-option">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb__links">
                <a href="/">
                  <i className="fa fa-home" /> Home
                </a>
                <span>Politique de confidentialité</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb End */}
      {/* Contact Section Begin */}
      <section className="contact spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="contact__content">
                <div className="contact__address">
                  <h5>Politique de confidentialité</h5>
                  <ul>
                    <li>
                      <h6>
                        <i className="fa fa-arrow-right" />
                        Introduction
                      </h6>
                      <p>
                        Devant le développement des nouveaux outils de
                        communication, il est nécessaire de porter une attention
                        particulière à la protection de la vie privée. C’est
                        pourquoi, nous nous engageons à respecter la
                        confidentialité des renseignements personnels que nous
                        collectons.
                      </p>
                    </li>

                    <li>
                      <h6>
                        <i className="fa fa-arrow-right" />
                        Collecte des renseignements personnels
                      </h6>
                      <p>
                        • Prénom
                        <br />
                        • Adresse postale
                        <br />
                        • Code postal
                        <br />
                        • Adresse électronique
                        <br />
                        • Numéro de téléphone / télécopieur
                        <br />
                        Les renseignements personnels que nous collectons sont
                        recueillis au travers de formulaires et grâce à
                        l’interactivité établie entre vous et notre site Web.
                        Nous utilisons également, comme indiqué dans la section
                        suivante, des fichiers témoins et/ou journaux pour
                        réunir des informations vous concernant.
                      </p>
                    </li>

                    <li>
                      <h6>
                        <i className="fa fa-arrow-right" />
                        Formulaires et interactivité:
                      </h6>
                      <p>
                        Vos renseignements personnels sont collectés par le
                        biais de formulaire, à savoir :<br />
                        • Formulaire d’inscription au site Web
                        <br />
                        • Formulaire de commande
                        <br />
                        Nous utilisons les renseignements ainsi collectés pour
                        les finalités suivantes : <br />
                        • Suivi de la commande
                        <br />
                        • Informations / Offres promotionnelles
                        <br />
                        • Statistiques
                        <br />
                        • Contact
                        <br />
                        Vos renseignements sont également collectés par le biais
                        de l’interactivité pouvant s’établir entre vous et notre
                        site Web et ce, de la façon suivante: <br />
                        • Commentaires
                        <br />
                        • Correspondance
                        <br />
                        Nous utilisons les renseignements ainsi collectés pour
                        les finalités suivantes : <br />
                        • Informations ou pour des offres promotionnelles
                        <br />
                        • Statistiques
                        <br />
                        • Contact
                        <br />
                      </p>
                    </li>

                    <li>
                      <h6>
                        <i className="fa fa-arrow-right" />
                        Droit d’opposition et de retrait
                      </h6>
                      <p>
                        Nous nous engageons à vous offrir un droit d’opposition
                        et de retrait quant à vos renseignements personnels. Le
                        droit d’opposition s’entend comme étant la possiblité
                        offerte aux internautes de refuser que leurs
                        renseignements personnels soient utilisées à certaines
                        fins mentionnées lors de la collecte. Le droit de
                        retrait s’entend comme étant la possiblité offerte aux
                        internautes de demander à ce que leurs renseignements
                        personnels ne figurent plus, par exemple, dans une liste
                        de diffusion. <br />
                        <b>
                          Pour pouvoir exercer ces droits, vous pouvez nous
                          contacter ici, et on prendra en charge votre demande
                          dans les plus brefs délais.{" "}
                        </b>
                      </p>
                    </li>

                    <li>
                      <h6>
                        <i className="fa fa-arrow-right" />
                        Droit d’accès
                      </h6>
                      <p>
                        Nous nous engageons à reconnaître un droit d’accès et de
                        rectification aux personnes concernées désireuses de
                        consulter, modifier, voire radier les informations les
                        concernant. <b>L’exercice de ce droit se fera</b> :Via
                        l’interface de votre compte ou en nous contactant par
                        ici.
                      </p>
                    </li>

                    <li>
                      <h6>
                        <i className="fa fa-arrow-right" />
                        Sécurité
                      </h6>
                      <p>
                        Les renseignements personnels que nous collectons sont
                        conservés dans un environnement sécurisé. Les personnes
                        travaillant pour nous sont tenues de respecter la
                        confidentialité de vos informations. Pour assurer la
                        sécurité de vos renseignements personnels, nous avons
                        recours aux mesures suivantes : <br />
                        • Protocole SSL
                        <br />
                        • Gestion des accès - personne autorisée
                        <br />
                        • Gestion des accès - personne concernée
                        <br />
                        • Sauvegarde informatique
                        <br />
                        • Identifiant / mot de passe
                        <br />
                        • Pare-feu
                        <br />
                        Nous nous engageons à maintenir un haut degré de
                        confidentialité en intégrant les dernières innovations
                        technologiques permettant d’assurer la confidentialité
                        de vos transactions. Toutefois, comme aucun mécanisme
                        n’offre une sécurité maximale, une part de risque est
                        toujours présente lorsque l’on utilise Internet pour
                        transmettre des renseignements personnels. <br />
                      </p>
                    </li>

                    <li>
                      <h6>
                        <i className="fa fa-arrow-right" />
                        Législation
                      </h6>
                      <p>
                        Nous nous engageons à respecter les dispositions
                        législatives énoncées dans la legislation tunisienne.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Politique;
