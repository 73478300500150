import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, refreshUser } from "../actions/auth";
import { useCookies } from "react-cookie";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import "./../custom.css";
import "./navbar.css";
import { useMediaQuery } from "react-responsive";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Header = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const cartItems = useSelector((state) => state.cart.items);
  const location = useLocation();

  useEffect(() => {
    setCartCount(cartItems.length);
  }, [cartItems]);
  useEffect(() => {
    dispatch(refreshUser(currentUser?.foundUser.id));
  }, []);

  const [cookies, setCookie] = useCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [list, setList] = useState([]);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [emailRegister, setEmailRegister] = useState("");
  const [tel, setTel] = useState("");
  const [role, setRole] = useState("");
  const [codePromo, setCodePromo] = useState("");
  const [mdp, setMdp] = useState("");
  const [error, setError] = useState("");
  const [errorRegister, setErrorRegister] = useState("");
  const [cartcount, setCartCount] = useState(0);
  const [menuresp, setMenuresp] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [searchvalue, setSearchValue] = useState('');
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    // Set redirectToShop to true to trigger the redirection
   
  };

  // Function to handle the button click
  const toggleHeader = () => {
    setIsHeaderVisible(!isHeaderVisible);
  };

  const handleChangePassword = (e) => {
    SetPassword(e.target.value);
  };

  const handleChangeUsername = (e) => {
    setEmail(e.target.value);
  };

  const Logout = async () => {
    try {
      await dispatch(logout());
      return <Navigate to="" />;
    } catch (error) {
      // handle error
    }
  };
  useEffect(() => {
    async function getListFromAPI() {
      try {
        const response = await axios.get("/categories"); // Replace with the actual API endpoint

        // Assuming the response data is an array, update the state with the list

        setList(response.data);
      } catch (error) {
        console.error("Error retrieving the list:", error);
      }
    }

    // Call the function to fetch the list from the API
    getListFromAPI();
  }, []);
  const handleLogin = (event) => {
    setNom("");
    setPrenom("");
    setAdresse("");
    setEmailRegister("");
    setTel("");
    setCodePromo("");
    setMdp("");
    setErrorRegister("");
    setError("");
    setEmail("");
    SetPassword("");
    // Perform validation if needed
    event.preventDefault();
    // Create an object with the username and password
    const data = {
      email: email,
      password: password,
    };
    dispatch(login(data.email, data.password))
      .then((data) => {
        // Reset the form values to null
        setEmail("");
        SetPassword("");

        // Close the modal
        const modal = document.getElementById("exampleModal");
        modal.classList.remove("show");
        modal.style.display = "none";
        document.body.classList.remove("modal-open");
        const modalBackdrop = document.querySelector(".modal-backdrop");
        modalBackdrop.parentNode.removeChild(modalBackdrop);
        if (data.foundUser.role === "user") {
          navigate("/");
        } else {
          navigate("/shop");
        }
        //  window.location.reload();
      })
      .catch((error) => {
        console.log("error : ", error);
        setError(error);
      });
    // Send the POST request to the backend
  };
  const errorMessageStyle = {
    color: "red",
    fontSize: "14px",
    marginTop: "5px",
  };
  const handleRegister = (event) => {
    // Perform validation if needed
    event.preventDefault();
    if (
      nom !== "" &&
      prenom !== "" &&
      adresse !== "" &&
      emailRegister !== "" &&
      tel !== "" &&
      mdp !== ""
    ) {
      // Create an object with the registration data
      const data = {
        nom,
        prenom,
        adresse,
        email: emailRegister,
        tel,
        role,
        code_promo: "",
        mdp,
        // ... other fields if needed
      };

      // Send the POST request to the backend
      axios
        .post("/signup", data)

        .then(async (response) => {
          // Handle successful response

          // Reset the form values to null
          setNom("");
          setPrenom("");
          setAdresse("");
          setEmailRegister("");
          setTel("");
          setCodePromo("");
          setMdp("");
          setErrorRegister("");
          setError("");
          setEmail("");
          SetPassword("");
          const response2 = await axios.post("/send-email-inscri", {
            email: emailRegister,
            nom: prenom,
          });

          // Close the modal
          const registerModal = document.getElementById("registerModal");
          registerModal.classList.remove("show");
          registerModal.style.display = "none";
          document.body.classList.remove("modal-open");
          const modalBackdrop = document.querySelector(".modal-backdrop");
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        })
        .catch((error) => {
          // Handle error response
          console.error(error.response.data.error);
          setErrorRegister(error.response.data.error);

          // You can display an error message or perform any other error handling here
        });
    }
  };

  const handleAnchorClick = (event) => {
    // Prevent the default behavior (navigation)
    event.preventDefault();

    // Print "Hello" to the console
    setNom("");
    setPrenom("");
    setAdresse("");
    setEmailRegister("");
    setTel("");
    setCodePromo("");
    setMdp("");
    setErrorRegister("");
    setError("");
    setEmail("");
    SetPassword("");

    // Optionally, you can open the modal manually using Bootstrap's methods if needed
    // const target = event.currentTarget.getAttribute('data-target');
    // $(target).modal('show');
  };

  const toggleMenresp = (event) => {
    event.preventDefault();
    setMenuresp((prevState) => !prevState);
  };
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const handleSearchToggle = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  useEffect(() => {
    if (searchvalue.trim() !== '') {
      navigate('/shop/'+searchvalue);
    }
  }, [searchvalue]);
  return (
    <>
      {/* start mobile menu */}
      <>
        <ToastContainer />
        <div
          className={
            isHeaderVisible
              ? "offcanvas-menu-overlay active"
              : "offcanvas-menu-overlay"
          }
        />
        <div
          className={
            isHeaderVisible
              ? "offcanvas-menu-wrapper active"
              : "offcanvas-menu-wrapper"
          }
        >
          <ul className="offcanvas__widget">
            <br />
          </ul>

          <div id="mobile-menu-wrap">
            <div className="slicknav_menu">
              <a
                href="/"
                aria-haspopup="true"
                role="button"
                tabIndex={0}
                className="slicknav_btn slicknav_collapsed"
                style={{ outline: "none" }}
              >
                <span className="slicknav_menutxt">MENU</span>
                <span className="slicknav_icon">
                  <span className="slicknav_icon-bar" />
                  <span className="slicknav_icon-bar" />
                  <span className="slicknav_icon-bar" />
                </span>
              </a>
              <a style={{ float: "right" }}>
                {" "}
                <i className="fa fa-times" onClick={toggleHeader} />
              </a>
              <nav
                className="slicknav_nav slicknav_hidden"
                aria-hidden="true"
                role="menu"
                style={{ display: "none" }}
              >
                <ul>
                  <li className={location.pathname === "/" && "active"}>
                    <Link to="/#" onClick={toggleHeader}>
                      Accueil{" "}
                    </Link>
                  </li>
                  <li
                    className={location.pathname.includes("/shop") && "active"}
                  >
                    <Link to="/shop">
                      Nos Produits
                      <span style={{ float: "right" }} onClick={toggleMenresp}>
                        {menuresp ? (
                          <i class="fa fa-chevron-up"></i>
                        ) : (
                          <i class="fa fa-chevron-down"></i>
                        )}
                      </span>
                    </Link>

                    {menuresp && (
                      <ul
                        className="dropdown"
                        style={{ backgroundColor: "#222", color: "white" }}
                      >
                        {list.map((cat, index) => (
                          <li key={index}>
                            <Link
                              to={"/shop/categorie/" + cat.id + "/" + cat.nom}
                              style={{ color: "white", marginLeft: "5px" }}
                              onClick={toggleHeader}
                            >
                              {cat.nom}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  <li className={location.pathname === "/promos" && "active"}>
                    <a href="#/promos" onClick={toggleHeader}>
                      Nos Promos
                    </a>
                  </li>
                  <li className={location.pathname === "/articles" && "active"}>
                    <a href="#/articles" onClick={toggleHeader}>
                      Blog
                    </a>
                  </li>
                  <li className={location.pathname === "/contact" && "active"}>
                    <a href="#/contact" onClick={toggleHeader}>
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="offcanvas__auth">
            {currentUser?.foundUser ? (
              <>
                <span>
                  {currentUser.foundUser.nom +
                    " " +
                    currentUser.foundUser.prenom}
                  <a className="mx-2" type="button" onClick={Logout}>
                    Logout
                  </a>
                </span>

                {/* Render additional authenticated user links if needed */}
              </>
            ) : (
              <>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={handleAnchorClick}
                >
                  Se connecter
                </a>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#registerModal"
                  onClick={handleAnchorClick}
                >
                  S'inscrire
                </a>
              </>
            )}
          </div>
        </div>
      </>
      {/* end mobile menu */}

      <header className="header sticky-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-lg-2">
              <div className="header__logo">
                <a href="/">
                  <img
                    src="/assets/img/logo.png"
                    style={{ width: "150px", height: "50px" }}
                    alt=""
                  />
                </a>
              </div>
            </div>

            <div className="col-xl-6 col-lg-7">
              <nav className="header__menu">
                <ul>
                  <li className={location.pathname === "/" && "active"}>
                    <Link to="/" onClick={toggleHeader}>
                      Accueil
                    </Link>
                  </li>
                  <li
                    className={location.pathname.includes("/shop") && "active"}
                  >
                    <Link to="/shop">Nos Produits</Link>
                    <ul className="dropdown">
                      {list.map((cat, index) => (
                        <li key={index}>
                          <Link
                            to={"/shop/categorie/" + cat.id + "/" + cat.nom}
                            onClick={toggleHeader}
                            style={{ marginBottom: "20px" }}
                          >
                            {cat.nom}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className={location.pathname === "/promos" && "active"}>
                    <a href="#/promos" onClick={toggleHeader}>
                      Nos Promos
                    </a>
                  </li>
                  <li className={location.pathname === "/articles" && "active"}>
                    <a href="#/articles" onClick={toggleHeader}>
                      Blog
                    </a>
                  </li>
                  <li className={location.pathname === "/contact" && "active"}>
                    <a href="#/contact" onClick={toggleHeader}>
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-3">
              <div className="header__right">
                <div className="header__right__auth">
                
                  {currentUser?.foundUser ? (
                    <>
                      <span>
                        <a className="mx-2" type="button" onClick={Logout}>
                          Logout
                        </a>
                      </span>

                      {/* Render additional authenticated user links if needed */}
                    </>
                  ) : (
                    <>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={handleAnchorClick}
                      >
                        Se connecter
                      </a>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#registerModal"
                        onClick={handleAnchorClick}
                      >
                        S'inscrire
                      </a>
                    </>
                  )}
                </div>

                <ul className="header__right__widget">
               
                  {currentUser?.foundUser && (
                    <li>
                      <a href="#/profile">
                        <i class="fa fa-user"></i>
                      </a>
                    </li>
                  )}

                  <li>
                    <a href="#/cart">
                      <span className="icon_bag_alt" />
                      <div className="tip">{cartcount}</div>
                    </a>
                  </li>
                  <li>
                    <span
                      className="icon_search search-switch"
                      onClick={handleSearchToggle}
                    />
                  </li>
                  
                  {isSearchVisible && (
                    <li>
                      <div className="input-group">
                      
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Recherche..."
                          aria-label="Search"
                          aria-describedby="search-addon"
                          value={searchvalue}
                          onChange={handleInputChange}
                        />
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="canvas__open">
            <div className="menu-container">
              <ul className="offcanvas__widget">
                {currentUser?.foundUser && (
                  <li>
                    <a href="#/profile">
                      <i class="fa fa-user"></i>
                    </a>
                  </li>
                )}

                <li>
                  <a href="#/cart">
                    <span className="icon_bag_alt" />
                    <div className="tip">{cartcount}</div>
                  </a>
                </li>
                <li>
                  {" "}
                  <i className="fa fa-bars" onClick={toggleHeader} />
                </li>
                <li>
                    <span
                      className="icon_search search-switch"
                      onClick={handleSearchToggle}
                    />
                  </li>
                  {isSearchVisible && (
                    <li>
                      <div className="input-group">
                      
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Recherche..."
                          aria-label="Search"
                          aria-describedby="search-addon"
                          value={searchvalue}
                          onChange={handleInputChange}
                        />
                      </div>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
        {/* Modal */}
        <div
          className="modal fade myform"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row justify-content-center">
                  <>
                    <div className="container">
                      <div className="card" />
                      <div className="card">
                        <h1 className="title">Se connecter</h1>
                        <form>
                          <div className="input-container">
                            <input
                              type="#{type}"
                              id="#{label}"
                              value={email}
                              onChange={handleChangeUsername}
                              required="required"
                            />
                            <label htmlFor="#{label}">Email</label>
                            <div className="bar" />
                          </div>
                          <div className="input-container">
                            <input
                              type="password"
                              id="#{label}"
                              value={password}
                              onChange={handleChangePassword}
                              required="required"
                            />
                            <label htmlFor="#{label}">Password</label>
                            <div className="bar" />
                          </div>
                          <div className="button-container">
                            <button onClick={handleLogin}>
                              <span>Se connecter</span>
                            </button>
                          </div>
                        </form>
                        {error !== "" && (
                          <div
                            className="col-12 error-message p-3 mx-2"
                            style={errorMessageStyle}
                          >
                            Veuillez vérifier votre identifiant et votre mot de
                            passe.
                          </div>
                        )}
                      </div>
                      <div className="card alt">
                        <div className="toggle" />
                        <h1 className="title">
                          <div className="close" />
                        </h1>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade myform"
          id="registerModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="registerModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row justify-content-center">
                  <>
                    <div className="container">
                      <div className="card" />
                      <div className="card">
                        <h1 className="title">S'inscrire</h1>
                        <form>
                          <div className="input-container">
                            <input
                              type="text"
                              id="nom"
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              required
                            />
                            <label htmlFor="nom">Nom</label>
                            <div className="bar" />
                          </div>
                          <div className="input-container">
                            <input
                              type="text"
                              id="prenom"
                              value={prenom}
                              onChange={(e) => setPrenom(e.target.value)}
                              required
                            />
                            <label htmlFor="prenom">Prenom</label>
                            <div className="bar" />
                          </div>
                          <div className="input-container">
                            <input
                              type="text"
                              id="adresse"
                              value={adresse}
                              onChange={(e) => setAdresse(e.target.value)}
                              required
                            />
                            <label htmlFor="adresse">Adresse</label>
                            <div className="bar" />
                          </div>
                          <div className="input-container">
                            <input
                              type="text"
                              id="emailRegister"
                              value={emailRegister}
                              onChange={(e) => setEmailRegister(e.target.value)}
                              required
                            />
                            <label htmlFor="emailRegister">Email</label>
                            <div className="bar" />
                          </div>
                          <div className="input-container">
                            <input
                              type="text"
                              id="tel"
                              value={tel}
                              onChange={(e) => setTel(e.target.value)}
                              required
                            />
                            <label htmlFor="tel">Tel</label>
                            <div className="bar" />
                          </div>
                          <div className="input-container">
                            <input
                              type="password"
                              id="mdp"
                              value={mdp}
                              onChange={(e) => setMdp(e.target.value)}
                              required
                            />
                            <label htmlFor="mdp">Mot de passe</label>
                            <div className="bar" />
                          </div>
                          <div className="button-container">
                            <button onClick={handleRegister}>
                              <span>S'inscrire</span>
                            </button>
                          </div>
                        </form>
                        {errorRegister && (
                          <div
                            className="col-6 error-message p-3 mx-2"
                            style={errorMessageStyle}
                          >
                            {errorRegister}
                          </div>
                        )}
                      </div>
                      <div className="card alt">
                        <div className="toggle" />
                        <h1 className="title">
                          Register
                          <div className="close" />
                        </h1>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
