// cartReducer.js
const initialState = {
  items: [],
  code_promo: "",
  remise: 0,
  livraison: 0,
  total_avant: 0,
  total_apres: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const existingItem = state.items.find(
        (item) => item.id === action.payload.id
      );

      if (existingItem) {
        // If the item already exists in the cart, update its quantity
        return {
          ...state,
          items: state.items.map((item) =>
            item.id === action.payload.id
              ? { ...item, quantity: item.quantity }
              : item
          ),
        };
      } else {
        // If the item does not exist in the cart, add it with the provided quantity or default to 1
        return {
          ...state,
          items: [
            ...state.items,
            { ...action.payload, quantity: action.payload.quantity || 1 },
          ],
        };
      }
    case "REMOVE_FROM_CART":
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };

    case "INCREMENT_QUANTITY":
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ),
      };

    case "DECREMENT_QUANTITY":
      return {
        ...state,
        items: state.items
          .map((item) =>
            item.id === action.payload
              ? { ...item, quantity: item.quantity - 1 }
              : item
          )
          .filter((item) => item.quantity > 0),
      };

    case "ADD_CODE_PROMO":
      return {
        ...state,
        code_promo: action.payload,
      };
    case "DELETE_CODE_PROMO":
      return {
        ...state,
        code_promo: "",
      };

    case "ADD_REMISE":
      return {
        ...state,
        remise: action.payload,
      };

    case "DELETE_REMISE":
      return {
        ...state,
        remise: 0,
      };

    case "ADD_LIVRAISON":
      return {
        ...state,
        livraison: action.payload,
      };

    case "DELETE_LIVRAISON":
      return {
        ...state,
        livraison: 0,
      };

    case "ADD_TOTAL_AVANT":
      return {
        ...state,
        total_avant: action.payload,
      };

    case "DELETE_TOTAL_AVANT":
      return {
        ...state,
        total_avant: 0,
      };

    case "ADD_TOTAL_APRES":
      return {
        ...state,
        total_apres: action.payload,
      };

    case "DELETE_TOTAL_APRES":
      return {
        ...state,
        total_apres: 0,
      };
      case "RESET_CART":
        // Reset the cart to an empty array
        return initialState;
    default:
      return state;
  }
};

export default cartReducer;
