import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet-async';
const Blog = () => {


  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await axios.get("/articles");
      setArticles(response.data);
    } catch (error) {
      console.error("Error fetching articles:", error);
      // Handle error cases here
    }
  };

  return (
    <>
     <Helmet>
        <meta name="description" content="Explorez nos conseils d'experts et trouvez l'inspiration pour créer des looks sensationnels. Que vous recherchiez des astuces pour un teint parfait, des idées de maquillage des yeux ou des recommandations de produits incontournables, notre article vous guide à travers les dernières nouveautés en matière de beauté. Faites l'expérience d'une transformation de votre routine de maquillage avec nos recommandations exclusives !" />
      </Helmet>
      <>
  {/* Breadcrumb Begin */}
 
  <div className="breadcrumb-option">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="breadcrumb__links">
            <a href="/">
              <i className="fa fa-home" /> Home
            </a>
            <span>Blog</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Breadcrumb End */}
  {/* Blog Section Begin */}
  <section className="blog spad">
    <div className="container">
      <div className="row">
      {articles.map((article) => (
        <div key={article.id} className="col-lg-4 col-md-4 col-sm-6">
          <div className="blog__item">
            <div
              className="blog__item__pic large__item set-bg"
              style={{ backgroundImage: `url(https://veracoslab.com/API/public/uploads/${article.image})` }}


            />
            <div className="blog__item__text">
              <h6>
                <a href={`#/article/${article.id}`}>{article.title}</a>
              </h6>
              <ul>
                <li>
                  by <span>VeracosLab</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}
   
       
      </div>
    </div>
  </section>
  {/* Blog Sec
   */}
</>

    </>
  )
}

export default Blog
