import React from "react";
import DataTable from "react-data-table-component";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, refreshUser,modifyUser } from "../actions/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [thisuser, setThisuser] = useState({});
  const [list, setList] = useState([]);


  const columns = [
    { name: "ID Commande", selector: "id", sortable: true },
    { name: "Total en TND", selector: "total", sortable: true },
    {
        name: "Date",
        selector: "createdAt", // Assuming the property name in your data is "date"
        sortable: true,
        cell: (row) => {
          // Convert "2023-08-03 13:36:54" to a formatted date string
          const formattedDate = moment(row.date).format('DD/MM/YYYY');
          return formattedDate;
        },
      },
    { name: "Etat",
    selector: "etat",
    sortable: true,
    // Use conditionalCellStyles to add custom styles based on the etat value
    
    // Use a cell function to render a custom message for each etat value
    cell: (row) => (
      <div>
        {row.etat === "0" && <span className="btn btn-warning">En att confirmation</span>}
        {row.etat === "1" && <span className="btn btn-info">Confirmée</span>}
        {row.etat === "2" && <span className="btn btn-primary">En att livraison</span>}
        {row.etat === "3" && <span className="btn btn-success">Livrée</span>}
        {row.etat === "4" && <span className="btn btn-danger">Annulée</span>}
      </div>
    ),
},
  {
    name: "Actions",
    cell: (row) => (
      <div>
        {/* Render the button */}
        <button className="btn btn-primary"  onClick={() => handleButtonClick(row)}>Click Me</button>
      </div>
    ),
  },
  ];

  const handleButtonClick = (row) => {
    // Access the data of the clicked row and perform any action you need.
    console.log("Button clicked for row:", row);
    // You can use the row data to update the state, trigger a modal, or perform any other action.
  };


  useEffect(() => {
    try {
      
      setNom(currentUser?.foundUser.nom);
      setPrenom(currentUser?.foundUser.prenom);
      setTel(currentUser?.foundUser.tel);
      setEmail(currentUser?.foundUser.email);
      setAdresse(currentUser?.foundUser.adresse);
    } catch {}
  }, []);
  const handleNomChange = (event) => {
    setNom(event.target.value);
  };

  const handlePrenomChange = (event) => {
    setPrenom(event.target.value);
  };

  const handleAdresseChange = (event) => {
    setAdresse(event.target.value);
  };

  const handleTelChange = (event) => {
    setTel(event.target.value);
  };


  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      const userData = {
        nom: nom,
        prenom: prenom,
        adresse: adresse,
        tel: tel,
      };
  
      // Make the Axios call to the /update endpoint
      const response = await axios.post(`/update_user/${currentUser?.foundUser.id}`, userData);
      console.log(response.data); // Updated user data
      const username = localStorage.getItem('user');
      //alert(username)
      
      dispatch(refreshUser(currentUser.foundUser.id));
      // If the update was successful, you might want to show a success message or perform additional actions
    } catch (error) {
      console.error(error);
      // Handle errors, show error message, etc.
    }
  };
  useEffect(() => {
    async function getListFromAPI() {
      try {
        const response = await axios.get( "/commandesbyuser/"+currentUser.foundUser.id); // Replace with the actual API endpoint

        // Assuming the response data is an array, update the state with the list
        setList(response.data);
    
      } catch (error) {
        console.error("Error retrieving the list:", error);
      }
    }

    
    // Call the function to fetch the list from the API
    getListFromAPI();
  }, []);
  
  return (
    <>
    <Helmet>
    
    <meta name="description" content="Veracos. Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté."/>

     </Helmet>
      <section className="contact spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="contact__content">
                <div className="contact__address">
                  <Tabs>
                    <TabList>
                    <Tab>
                        <h5>Mes commandes</h5>
                      </Tab>
                      <Tab>
                        <h5>Mes coordonnées</h5>
                      </Tab>
                     
                    </TabList>

                 
                    <TabPanel>
                      <div className="contact__form">
                        <DataTable columns={columns} data={list} />
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="contact__form">
                        <form action="">
                            <label><p>Nom : </p></label>
                          <input
                            type="text"
                            value={nom}
                            placeholder="Nom"
                            onChange={handleNomChange}
                          />
                           <label><p>Prénom : </p></label>
                          <input
                            type="text"
                            value={prenom}
                            placeholder="Prénom"
                            onChange={handlePrenomChange}
                          />
                           <label><p>Email : </p></label>
                          <input
                            type="text"
                            value={email}
                            placeholder="Email"
                            disabled
                          />
                           <label><p>Adresse : </p></label>
                          <input
                            type="text"
                            value={adresse}
                            placeholder="Adresse"
                            onChange={handleAdresseChange}
                          />
                           <label><p>Téléphone : </p></label>
                          <input
                            type="text"
                            value={tel}
                            placeholder="Téléphone"
                            onChange={handleTelChange}
                          />

                          <button className="site-btn" style={{float:"right"}} onClick={handleUpdate}>
                            Enregistrer les modifications
                          </button>
                        </form>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
