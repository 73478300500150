import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
const Footer = () => {
  const [statics, setStatics] = useState([]);

  useEffect(() => {
    async function fetchStatics() {
      try {
        const response = await axios.get('/statics');
        setStatics(response.data);
      } catch (error) {
        console.error("Error fetching statics:", error);
      }
    }

    fetchStatics();
  }, []);

  const footerStatics = statics.filter((item) => item.type === "footer");
  return (
    <>
    <div className="instagram">
  <div className="container-fluid">
    <div className="row">
    {footerStatics.slice(0, 6).map((item, index) => (
  <div className="col-lg-2 col-md-4 col-sm-4 p-0" key={index}>
    <div
      className="instagram__item set-bg"
      style={{ backgroundImage:  `url("https://veracoslab.com/API/public/uploads/${item.image}")` }}
    >
      <div className="instagram__text">
        <i className="fa fa-instagram" />
        <a target="_blank" href="https://www.instagram.com/veracoslab/">@ Veracos Lab</a>
      </div>
    </div>
  </div>
))}
     
   
  
    </div>
  </div>
</div>

      <footer className="footer">
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-md-6 col-sm-7">
        <div className="footer__about">
          <div className="footer__logo">
            <a href="/">
              <img src="assets/img/logo.png"  width={"200px"} alt="" />
            </a>
          </div>
          <p>
          Découvrez VercaosLab, où la beauté rencontre l'innovation. Notre exceptionnelle gamme de cosmétiques de haute qualité est conçue pour sublimer vos traits naturels et vous permettre d'exprimer votre style unique en toute confiance. Expérimentez le luxe de VercaosLab et révélez dès aujourd'hui tout votre potentiel de beauté.
          </p>
    
        </div>
      </div>
      <div className="col-lg-2 col-md-3 col-sm-5">
        <div className="footer__widget">
          <h6>Contact</h6>
          <ul>
            <li>
              <a href="#/contact"><b>Téléphone</b></a>
            </li>
            <li>
              <a href="#/contact">51 452 500 / 31 400 700</a>
            </li>
            <li>
              <a href="#/contact"><b>E-mail</b></a>
            </li>
            <li>
              <a href="#/contact">contact@veracoslab.com</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-2 col-md-3 col-sm-4">
        <div className="footer__widget">
          <h6>Liens utiles</h6>
          <ul>
          
            
            <li>
              <a href="#/cgv">CGV</a>
            </li>
            <li>
              <a href="#/politique">Politique de confidentialité</a>
            </li>
            <li>
              <a href="#/contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-4 col-md-8 col-sm-8">
        <div className="footer__newslatter">
          <h6>NEWSLETTER</h6>
          <form action="#">
            <input type="text" placeholder="Email" />
            <button type="submit" className="site-btn">
              S'inscrire
            </button>
          </form>
          <div className="footer__social">
            <a target="_blank" href="https://www.facebook.com/veracoslab">
              <i className="fa fa-facebook" href="https://www.facebook.com/veracoslab"/>
            </a>
          
          
            <a target="_blank" href="https://www.instagram.com/veracoslab/">
              <i className="fa fa-instagram" />
            </a>

            <a target="_blank" href="https://www.tiktok.com/@veracoslab">
              <i className="fa fa-music" />
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCP5kdgAQge03F1zjHJ6YtMg">
              <i className="fa fa-youtube" />
            </a>
            <a target="_blank" href="https://www.pinterest.fr/veracoslab/">
              <i className="fa fa-pinterest" />
            </a>
          
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
        <div className="footer__copyright__text">
          <p>
            Copyright © All rights reserved by VeracosLab
          </p>
        </div>
        {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
      </div>
    </div>
  </div>
</footer>

    </>
  )
}

export default Footer
