import React, { useState } from 'react';
import './../custom.css';
import { Helmet } from 'react-helmet-async';
const Login = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  
    const handleToggleMode = () => {
      setIsLogin(!isLogin);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Perform login or sign-up logic here
      console.log('Submitted:', email, password);
      // Reset form fields
      setEmail('');
      setPassword('');
    };
  
    return (
       
      <div className="auth-container" style={{marginTop:"50px",marginBottom:"50px"}}>
        <h2>{isLogin ? 'Connexion' : 'Inscription'}</h2>
        <form className="auth-form" onSubmit={handleSubmit}>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Mot de passe</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">{isLogin ? 'Se connecter' : 'S\'inscrire'}</button>
          <p onClick={handleToggleMode}>
            {isLogin
              ? 'Pas encore de compte? Créer un compte'
              : 'Déjà un compte? Se connecter'}
          </p>
        </form>
        <br/><br/>
      </div>
      
    );
  };
  

export default Login
