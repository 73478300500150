import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const timeRemaining = targetDate - now;

    const daysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hoursRemaining = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesRemaining = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );
    const secondsRemaining = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return {
      days: daysRemaining,
      hours: hoursRemaining,
      minutes: minutesRemaining,
      seconds: secondsRemaining,
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className="discount__countdown">
      <div className="countdown__item">
        <span>{timeRemaining.days}</span>
        <p>Jours</p>
      </div>
      <div className="countdown__item">
        <span>{timeRemaining.hours}</span>
        <p>Heures</p>
      </div>
      <div className="countdown__item">
        <span>{timeRemaining.minutes}</span>
        <p>Minutes</p>
      </div>
      <div className="countdown__item">
        <span>{timeRemaining.seconds}</span>
        <p>Secondes</p>
      </div>
    </div>
  );
};

export default CountdownTimer;
