import React from 'react'
import { Link } from "react-router-dom";
const trend_item = (props) => {
    const index=props.index;
    const img=props.img;
    const promo=props.promo;
    const stock=props.stock;
    const titre=props.titre;
    const prix=props.prix;
    const prodId=props.prodId;
    const rating=props.rating;
    const intValue = parseInt(rating, 10);
    const deb=props.deb;
    const fin=props.fin;
    const currentDate = new Date();
    const debDate = new Date(deb);
    const finDate = new Date(fin);
    
    const periode= (currentDate >= debDate && currentDate <= finDate) ;
   
   
    let newPrice=0;
    
    if(promo!=="0" && periode)
    {
     const promoValue = parseInt(promo); // Convert promo string to an integer
       const prixValue = parseFloat(prix); // Convert prix string to a float
   
       const discount = (prixValue * promoValue) / 100;
        newPrice = prixValue - discount;
    
    }
   
   
  return (
    <>
     <Link to={`../product/${prodId}/${titre.replace(/ /g, '-')}`} className="image-popup">
     
    
       <div className="trend__item" key={index}>
        
                      <div className="trend__item__pic">
                        <img
                          src={`https://veracoslab.com/API/public/uploads/${img}`}
                          alt=""
                          width="90px"
                          height="90px"
                        />
                        
                      </div>
                      <div className="trend__item__text">
                        <h6>{titre}</h6>
                        <div className="rating">
  {Array.from({ length: intValue }).map((_, index) => (
    <i key={index} className="fa fa-star" style={{ marginRight: "3px" }} />
  ))}
</div>
                        {periode===false  && (<div className="product__price">{prix} TND</div>)}
              {(promo!=="0" && periode ) && ( <div className="product__price" style={{color:"#Be2525"}}>{newPrice} TND <span>{prix}</span></div>)}
             
                      </div>
                    </div>
                    </Link>
    </>
  )
}

export default trend_item
