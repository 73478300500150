import logo from './logo.svg';
import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import Footer from './footer/footer.js';
import Home from './main/home.js';
import Shop from './main/shop.js';
import Search from './main/search.js';
import Promos from './main/promos.js';
import Contact from './main/contact.js';
import Cart from './main/cart.js';
import Blog from './main/blog.js';
import Article from './main/article.js'
import Single_product from './main/single_product';
import Billing from './main/billing'
import CGV from './main/cgv'
import Login from './main/login'
import Notfound from './main/not_found'
import Profile from './main/profile'
import Billing_success from './main/components/billing_success'
import Commandes from './main/commandes'
import Politique from './main/politique'
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';

import Header from './header/header.js';
import axios from "axios";

const ScrollToTop = () => {
  useEffect(() => {
    const handleHashChange = () => {
      // Scroll to the top of the page when the hash changes (route changes)
      window.scrollTo(0, 0);
    };

    // Listen for the hashchange event and call handleHashChange when it happens
    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return null;
};


function App() {
  axios.defaults.baseURL = "https://veracoslab.com/api";

 

  const ScrollToTop = () => {
    const location = useLocation();
  
    useEffect(() => {
      // Scroll to the top of the page when the route changes
      window.scrollTo(0, 0);
    }, [location]);
  
    return null;
  };


  return (

    <>
<HelmetProvider>
<Header />

<ScrollToTop />
    
     
      <Routes>
        
     
   <Route path="/" element={<Home />} />
   <Route path="/product/:productId/:titre" element={<Single_product />} />
   <Route path="/shop" element={<Shop />} />
   <Route path="/shop/:titre" element={<Search />} />
   <Route path="/contact" element={<Contact />} />
   <Route path="/cart" element={<Cart />} />
   <Route path="/articles" element={<Blog />} />
   <Route path="/article/:id" element={<Article />} />
   <Route path="/billing" element={<Billing />} />
   <Route path="/article" element={<Article />} />
   <Route path="/cgv" element={<CGV />} />
   <Route path="/politique" element={<Politique />} />

   <Route path="/profile" element={<Profile />} />
   <Route path="/commandes" element={<Commandes />} />
   <Route path="/login" element={<Login />} />
   <Route path="/promos" element={<Promos />} />
   <Route path="/success" element={<Billing_success />} />
   <Route path="/shop/:type/:id/:nom" element={<Shop />} />
   <Route path="*" element={<Notfound />} />

   </Routes>
  
      <Footer />
   
</HelmetProvider>





    
    </>
 
  );
}

export default App;
