import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} from "./../../actions/action";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Produit_component.css'; // Import CSS file for styling

const Produit_component = (props) => {
  const {
    cartItems,
    addToCart,
    removeFromCart,
    incrementQuantity,
    decrementQuantity,
  } = props;
  const [cookies, setCookie] = useCookies();
  const img_url = props.img_url;
  const titre = props.titre;
  const prix = props.prix;
  const prodId = props.prodId;
  const width = props.width;
  const height = props.height;
  const promo = props.promo;
  const stock = props.stock;
  const rating = props.rating;
  const deb = props.deb;
  const fin = props.fin;
  const currentDate = new Date();
  const debDate = new Date(deb);
  const finDate = new Date(fin);
  const periode = currentDate >= debDate && currentDate <= finDate;

  const [newp, setNewp] = useState();
  const intValue = parseInt(rating, 10);
  let newPrice = 0;

  const dispatch = useDispatch();
  const notify = (msg) => {
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_RIGHT_RIGHT
    });
  };

  const handleAddToCart = (event, product) => {
    event.preventDefault();
    dispatch(addToCart(product));
    notify("ajouté au panier!");
  };

  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const handleIncrement = (productId) => {
    incrementQuantity(productId);
  };

  const handleDecrement = (productId) => {
    decrementQuantity(productId);
  };

  if (promo !== "0" && periode) {
    const promoValue = parseInt(promo); // Convert promo string to an integer
    const prixValue = parseFloat(prix); // Convert prix string to a float

    const discount = (prixValue * promoValue) / 100;
    newPrice = prixValue - discount;
  } else {
    newPrice = prix;
  }

  const handleButtonClick = (e) => {
    e.preventDefault();
    // Set the cookie
    //setCookie('myCookieName', 'myCookieValue', { path: '/' });
    const existingValue = cookies.mycart;
    const newValue = "/" + prodId + "/";
    if (existingValue) {
      // Cookie exists, add "/x" to its existing value
      if (existingValue.includes(newValue) === false)
        setCookie("mycart", existingValue + prodId + "/", { path: "/" });
    } else {
      // Cookie doesn't exist, create it
      setCookie("mycart", "/" + prodId + "/", { path: "/" });
    }
  };

  useEffect(() => {
    setNewp(newPrice);
  }, []);
 
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleTouchStart = () => {
    setIsHovered(true);
  };

  const handleTouchEnd = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Link to={`../product/${prodId}/${titre.replace(/ /g, '-')}`} className="image-popup">
        <div 
          className="product__item" 
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className="product__item__pic set-bg"
            style={{
              backgroundImage: `url(${img_url})`,
              width: width,
              height: height,
            }}
            alt={props.titre_img}
            descrip={props.description_img}
          >
            {promo !== "0" && periode && (
              <div className="label sale">En promo</div>
            )}
            {stock === "0" && <div className="label stockout">En rupture</div>}
            <ul className="product__hover">
              <li>
                <Link to={`../product/${prodId}/${titre.replace(/ /g, '-')}`} className="image-popup">
                  <span className="arrow_expand" />
                </Link>
              </li>

              <li>
                <a
                  href="#"
                  onClick={(event) =>
                    handleAddToCart(event, {
                      id: prodId,
                      titre: titre,
                      img: img_url,
                      prix: newp,
                      promo: promo,
                      rating: rating,
                      quantity: 1,
                    })
                  }
                  id={prodId}
                >
                  <span className="icon_bag_alt" />
                </a>
              </li>
            </ul>
          </div>
          {isHovered && (
            <div className="image-overlay">
              <img src={img_url} alt={props.titre_img} />
            </div>
          )}
          <div className="product__item__text">
          <Link to={`../product/${prodId}/${titre.replace(/ /g, '-')}`} className="image-popup">
              <h6>
                <a href="#">{titre}</a>
              </h6>
            </Link>

            <div className="rating">
              {Array.from({ length: intValue }).map((_, index) => (
                <i
                  key={index}
                  className="fa fa-star"
                  style={{ marginRight: "3px" }}
                />
              ))}
            </div>
            {periode === false && (
              <div className="product__price">{prix.replace("$", "")} TND</div>
            )}
            {promo !== "0" && periode && (
              <div className="product__price" style={{ color: "#Be2525" }}>
                {newPrice.toFixed(2)} TND <span>{prix.replace("$", "")} </span>
              </div>
            )}
          </div>
        </div>
      </Link>
    </>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
});

const mapDispatchToProps = {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
};
export default connect(mapStateToProps, mapDispatchToProps)(Produit_component);
